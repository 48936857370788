/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";

import * as Types from "../../generated/types";

export type ExpressionArgAtomDetailsFragment = {
  __typename: "ExpressionArgAtom";
  type: Types.ExpressionArgType;
  atom: string;
};

export type ExpressionArgAttributeDetailsFragment = {
  __typename: "ExpressionArgAttribute";
  type: Types.ExpressionArgType;
  attribute: Array<string>;
};

export type ExpressionArgStringLiteralDetailsFragment = {
  __typename: "ExpressionArgStringLiteral";
  stringLiteral: string;
  type: Types.ExpressionArgType;
};

export type ExpressionArgBooleanLiteralDetailsFragment = {
  __typename: "ExpressionArgBooleanLiteral";
  booleanLiteral: boolean;
  type: Types.ExpressionArgType;
};

export type ExpressionArgIntegerLiteralDetailsFragment = {
  __typename: "ExpressionArgIntegerLiteral";
  integerLiteral: number;
  type: Types.ExpressionArgType;
};

export type ExpressionArgListOfStringsLiteralDetailsFragment = {
  __typename: "ExpressionArgListOfStringsLiteral";
  listOfStrings: Array<string>;
  type: Types.ExpressionArgType;
};

export type ExpressionConnectorDetailsFragment = {
  __typename: "ExpressionConnector";
  connector: Types.ExpressionConnectorType;
};

export type ExpressionFunctionDetailsFragment = {
  __typename: "ExpressionFunction";
  hasNegation: boolean;
  type: string;
  args: Array<
    | ({ __typename: "ExpressionArgAtom" } & ExpressionArgAtomDetailsFragment)
    | ({ __typename: "ExpressionArgAttribute" } & ExpressionArgAttributeDetailsFragment)
    | ({ __typename: "ExpressionArgBooleanLiteral" } & ExpressionArgBooleanLiteralDetailsFragment)
    | ({ __typename: "ExpressionArgIntegerLiteral" } & ExpressionArgIntegerLiteralDetailsFragment)
    | ({ __typename: "ExpressionArgListOfStringsLiteral" } & ExpressionArgListOfStringsLiteralDetailsFragment)
    | ({ __typename: "ExpressionArgStringLiteral" } & ExpressionArgStringLiteralDetailsFragment)
  > | null;
};

export type ExpressionDetailsFragment = {
  __typename: "Expression";
  raw: string;
  type: Types.ExpressionType;
  items: Array<
    | ({ __typename: "ExpressionConnector" } & ExpressionConnectorDetailsFragment)
    | ({ __typename: "ExpressionFunction" } & ExpressionFunctionDetailsFragment)
  > | null;
};

export type StackCardItemDetailsStackCardItemAiAddFunctionFragment = {
  __typename: "StackCardItemAiAddFunction";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemAiAddFunctionPayload";
    connection: string;
    name: string;
    description: string;
    parameters: string;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemAiAddMessageFragment = {
  __typename: "StackCardItemAiAddMessage";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: { __typename: "StackCardItemAiAddMessagePayload"; connection: string; role: string; message: string } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemAiAddMessagesFragment = {
  __typename: "StackCardItemAiAddMessages";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemAiAddMessagesPayload";
    connection: string;
    role: string;
    messages: Array<string>;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemAiAssistantFragment = {
  __typename: "StackCardItemAiAssistant";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: { __typename: "StackCardItemAiAssistantPayload"; uuid: string } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemAiChatCompletionFragment = {
  __typename: "StackCardItemAiChatCompletion";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: { __typename: "StackCardItemAiChatCompletionPayload"; connection: string } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemAiConnectFragment = {
  __typename: "StackCardItemAiConnect";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: { __typename: "StackCardItemAiConnectPayload"; vendor: string; model: string; token: string } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemAiTextClassificationFragment = {
  __typename: "StackCardItemAiTextClassification";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemAiTextClassificationPayload";
    connection: string;
    userInput: string;
    systemPrompt: string;
    options: Array<string>;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemAskFragment = {
  __typename: "StackCardItemAsk";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  prompt: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemAudioFragment = {
  __typename: "StackCardItemAudio";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  url: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemButtonFragment = {
  __typename: "StackCardItemButton";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  references: Array<{ __typename: "CardReference"; card: string | null; label: string | null }>;
  items: Array<
    | { __typename: "StackCardItemAiAddFunction"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiAddMessage"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiAddMessages"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiAssistant"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiChatCompletion"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiConnect"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiTextClassification"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAsk"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAudio"; type: Types.StackCardPlanStep; url: string | null }
    | { __typename: "StackCardItemButton"; type: Types.StackCardPlanStep }
    | {
        __typename: "StackCardItemDocument";
        filename: string | null;
        type: Types.StackCardPlanStep;
        url: string | null;
      }
    | { __typename: "StackCardItemDynamicButton"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemDynamicList"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemExpression"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemFooter"; type: Types.StackCardPlanStep; footerText: string | null }
    | { __typename: "StackCardItemHeader"; type: Types.StackCardPlanStep; headerText: string | null }
    | { __typename: "StackCardItemImage"; type: Types.StackCardPlanStep; url: string | null }
    | { __typename: "StackCardItemLelapaConnect"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLelapaEntityRecognition"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLelapaIntentClassification"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLelapaSentimentAnalysis"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLelapaTranslate"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemList"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLiteral"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLog"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAddFunction"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAddImage"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAddMessage"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAddMessages"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAssistant"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiChatCompletion"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiConnect"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiCreateSpeech"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiTranscribe"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemRunStack"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemScheduleStack"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemSendContent"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemSetChatProperty"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemSetMessageProperty"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemText"; type: Types.StackCardPlanStep; text: string | null }
    | { __typename: "StackCardItemThen"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemUpdateContact"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemUpdateDictionary"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemVideo"; type: Types.StackCardPlanStep; url: string | null }
    | { __typename: "StackCardItemWebhook"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemWhatsappFlow"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemWhatsappTemplateMessage"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemWriteResult"; type: Types.StackCardPlanStep }
  >;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemDocumentFragment = {
  __typename: "StackCardItemDocument";
  filename: string | null;
  variableName: string | null;
  type: Types.StackCardPlanStep;
  url: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemDynamicButtonFragment = {
  __typename: "StackCardItemDynamicButton";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemDynamicListFragment = {
  __typename: "StackCardItemDynamicList";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemExpressionFragment = {
  __typename: "StackCardItemExpression";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  expression: string | null;
  prompt: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemFooterFragment = {
  __typename: "StackCardItemFooter";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  footerText: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemHeaderFragment = {
  __typename: "StackCardItemHeader";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  headerText: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemImageFragment = {
  __typename: "StackCardItemImage";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  url: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemLelapaConnectFragment = {
  __typename: "StackCardItemLelapaConnect";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemLelapaEntityRecognitionFragment = {
  __typename: "StackCardItemLelapaEntityRecognition";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemLelapaIntentClassificationFragment = {
  __typename: "StackCardItemLelapaIntentClassification";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemLelapaSentimentAnalysisFragment = {
  __typename: "StackCardItemLelapaSentimentAnalysis";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemLelapaTranslateFragment = {
  __typename: "StackCardItemLelapaTranslate";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemListFragment = {
  __typename: "StackCardItemList";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  listReferences: {
    __typename: "StackCardItemListButtonReference";
    callToAction: string | null;
    listButtons: Array<{ __typename: "CardReference"; card: string | null; label: string | null }>;
  } | null;
  listItems: Array<
    | { __typename: "StackCardItemAiAddFunction"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemAiAddMessage"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemAiAddMessages"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemAiAssistant"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemAiChatCompletion"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemAiConnect"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemAiTextClassification"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemAsk"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemAudio"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemButton"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemDocument"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemDynamicButton"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemDynamicList"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemExpression"; type: Types.StackCardPlanStep; variableName: string | null }
    | {
        __typename: "StackCardItemFooter";
        type: Types.StackCardPlanStep;
        variableName: string | null;
        footerText: string | null;
      }
    | {
        __typename: "StackCardItemHeader";
        type: Types.StackCardPlanStep;
        variableName: string | null;
        headerText: string | null;
      }
    | {
        __typename: "StackCardItemImage";
        type: Types.StackCardPlanStep;
        variableName: string | null;
        url: string | null;
      }
    | { __typename: "StackCardItemLelapaConnect"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemLelapaEntityRecognition"; type: Types.StackCardPlanStep; variableName: string | null }
    | {
        __typename: "StackCardItemLelapaIntentClassification";
        type: Types.StackCardPlanStep;
        variableName: string | null;
      }
    | { __typename: "StackCardItemLelapaSentimentAnalysis"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemLelapaTranslate"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemList"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemLiteral"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemLog"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiAddFunction"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiAddImage"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiAddMessage"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiAddMessages"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiAssistant"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiChatCompletion"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiConnect"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiCreateSpeech"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemOpenaiTranscribe"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemRunStack"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemScheduleStack"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemSendContent"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemSetChatProperty"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemSetMessageProperty"; type: Types.StackCardPlanStep; variableName: string | null }
    | {
        __typename: "StackCardItemText";
        type: Types.StackCardPlanStep;
        variableName: string | null;
        text: string | null;
      }
    | {
        __typename: "StackCardItemThen";
        type: Types.StackCardPlanStep;
        variableName: string | null;
        then: {
          __typename: "StackCardItemThenPayload";
          card: string;
          expression: ({ __typename: "Expression" } & ExpressionDetailsFragment) | null;
        };
      }
    | { __typename: "StackCardItemUpdateContact"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemUpdateDictionary"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemVideo"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemWebhook"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemWhatsappFlow"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemWhatsappTemplateMessage"; type: Types.StackCardPlanStep; variableName: string | null }
    | { __typename: "StackCardItemWriteResult"; type: Types.StackCardPlanStep; variableName: string | null }
  >;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemLiteralFragment = {
  __typename: "StackCardItemLiteral";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemLogFragment = {
  __typename: "StackCardItemLog";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiAddFunctionFragment = {
  __typename: "StackCardItemOpenaiAddFunction";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemOpenaiAddFunctionPayload";
    connection: string;
    description: string;
    name: string;
    parameters: string;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiAddImageFragment = {
  __typename: "StackCardItemOpenaiAddImage";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemOpenaiAddImagePayload";
    connection: string;
    maxTokens: string;
    message: string;
    role: string;
    url: string;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiAddMessageFragment = {
  __typename: "StackCardItemOpenaiAddMessage";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemOpenaiAddMessagePayload";
    connection: string;
    role: string;
    message: string;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiAddMessagesFragment = {
  __typename: "StackCardItemOpenaiAddMessages";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemOpenaiAddMessagesPayload";
    connection: string;
    role: string;
    messages: Array<string>;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiAssistantFragment = {
  __typename: "StackCardItemOpenaiAssistant";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiChatCompletionFragment = {
  __typename: "StackCardItemOpenaiChatCompletion";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: { __typename: "StackCardItemOpenaiChatCompletionPayload"; connection: string } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiConnectFragment = {
  __typename: "StackCardItemOpenaiConnect";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: { __typename: "StackCardItemOpenaiConnectPayload"; token: string; model: string } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiCreateSpeechFragment = {
  __typename: "StackCardItemOpenaiCreateSpeech";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemOpenaiCreateSpeechPayload";
    connection: string;
    model: string;
    text: string;
    voice: string;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemOpenaiTranscribeFragment = {
  __typename: "StackCardItemOpenaiTranscribe";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  config: {
    __typename: "StackCardItemOpenaiTranscribePayload";
    connection: string;
    language: string;
    prompt: string;
    url: string;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemRunStackFragment = {
  __typename: "StackCardItemRunStack";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  runStack: { __typename: "RunStackParameters"; flowId: string | null } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemScheduleStackFragment = {
  __typename: "StackCardItemScheduleStack";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemSendContentFragment = {
  __typename: "StackCardItemSendContent";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  sendContent: { __typename: "StackCardItemSendContentPayload"; contentUuid: string; waitForInput: string } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemSetChatPropertyFragment = {
  __typename: "StackCardItemSetChatProperty";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  setChatProperty: { __typename: "StackCardSetChatProperty"; assignedTo: string | null };
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemSetMessagePropertyFragment = {
  __typename: "StackCardItemSetMessageProperty";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  setMessageProperty: { __typename: "StackCardSetMessageProperty"; label: string };
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemTextFragment = {
  __typename: "StackCardItemText";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  text: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemThenFragment = {
  __typename: "StackCardItemThen";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  then: {
    __typename: "StackCardItemThenPayload";
    card: string;
    expression: ({ __typename: "Expression" } & ExpressionDetailsFragment) | null;
  };
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemUpdateContactFragment = {
  __typename: "StackCardItemUpdateContact";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  contactFields: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemUpdateDictionaryFragment = {
  __typename: "StackCardItemUpdateDictionary";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemVideoFragment = {
  __typename: "StackCardItemVideo";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  url: string | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemWebhookFragment = {
  __typename: "StackCardItemWebhook";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  webhook: {
    __typename: "StackCardWebhook";
    body: string | null;
    method: Types.WebhookHttpMethodType | null;
    query: string | null;
    url: string | null;
    variableName: string | null;
    expression: boolean | null;
    mode: Types.WebhookModeType | null;
    timeout: number | null;
    headers: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
  } | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemWhatsappFlowFragment = {
  __typename: "StackCardItemWhatsappFlow";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemWhatsappTemplateMessageFragment = {
  __typename: "StackCardItemWhatsappTemplateMessage";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  whatsappTemplateMessage: {
    __typename: "StackCardItemWhatsappTemplateMessagePayload";
    bodyParams: Array<string> | null;
    headerParams: Array<string> | null;
    urlParams: Array<string> | null;
    language: string;
    name: string;
  };
  mediaItems: Array<
    | { __typename: "StackCardItemAiAddFunction"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiAddMessage"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiAddMessages"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiAssistant"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiChatCompletion"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiConnect"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAiTextClassification"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAsk"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemAudio"; type: Types.StackCardPlanStep; url: string | null }
    | { __typename: "StackCardItemButton"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemDocument"; type: Types.StackCardPlanStep; url: string | null }
    | { __typename: "StackCardItemDynamicButton"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemDynamicList"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemExpression"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemFooter"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemHeader"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemImage"; type: Types.StackCardPlanStep; url: string | null }
    | { __typename: "StackCardItemLelapaConnect"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLelapaEntityRecognition"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLelapaIntentClassification"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLelapaSentimentAnalysis"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLelapaTranslate"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemList"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLiteral"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemLog"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAddFunction"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAddImage"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAddMessage"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAddMessages"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiAssistant"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiChatCompletion"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiConnect"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiCreateSpeech"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemOpenaiTranscribe"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemRunStack"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemScheduleStack"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemSendContent"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemSetChatProperty"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemSetMessageProperty"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemText"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemThen"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemUpdateContact"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemUpdateDictionary"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemVideo"; type: Types.StackCardPlanStep; url: string | null }
    | { __typename: "StackCardItemWebhook"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemWhatsappFlow"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemWhatsappTemplateMessage"; type: Types.StackCardPlanStep }
    | { __typename: "StackCardItemWriteResult"; type: Types.StackCardPlanStep }
  > | null;
  buttons: Array<{ __typename: "CardReference"; card: string | null; label: string | null }> | null;
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsStackCardItemWriteResultFragment = {
  __typename: "StackCardItemWriteResult";
  variableName: string | null;
  type: Types.StackCardPlanStep;
  resultFields: { __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType };
  meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
};

export type StackCardItemDetailsFragment =
  | StackCardItemDetailsStackCardItemAiAddFunctionFragment
  | StackCardItemDetailsStackCardItemAiAddMessageFragment
  | StackCardItemDetailsStackCardItemAiAddMessagesFragment
  | StackCardItemDetailsStackCardItemAiAssistantFragment
  | StackCardItemDetailsStackCardItemAiChatCompletionFragment
  | StackCardItemDetailsStackCardItemAiConnectFragment
  | StackCardItemDetailsStackCardItemAiTextClassificationFragment
  | StackCardItemDetailsStackCardItemAskFragment
  | StackCardItemDetailsStackCardItemAudioFragment
  | StackCardItemDetailsStackCardItemButtonFragment
  | StackCardItemDetailsStackCardItemDocumentFragment
  | StackCardItemDetailsStackCardItemDynamicButtonFragment
  | StackCardItemDetailsStackCardItemDynamicListFragment
  | StackCardItemDetailsStackCardItemExpressionFragment
  | StackCardItemDetailsStackCardItemFooterFragment
  | StackCardItemDetailsStackCardItemHeaderFragment
  | StackCardItemDetailsStackCardItemImageFragment
  | StackCardItemDetailsStackCardItemLelapaConnectFragment
  | StackCardItemDetailsStackCardItemLelapaEntityRecognitionFragment
  | StackCardItemDetailsStackCardItemLelapaIntentClassificationFragment
  | StackCardItemDetailsStackCardItemLelapaSentimentAnalysisFragment
  | StackCardItemDetailsStackCardItemLelapaTranslateFragment
  | StackCardItemDetailsStackCardItemListFragment
  | StackCardItemDetailsStackCardItemLiteralFragment
  | StackCardItemDetailsStackCardItemLogFragment
  | StackCardItemDetailsStackCardItemOpenaiAddFunctionFragment
  | StackCardItemDetailsStackCardItemOpenaiAddImageFragment
  | StackCardItemDetailsStackCardItemOpenaiAddMessageFragment
  | StackCardItemDetailsStackCardItemOpenaiAddMessagesFragment
  | StackCardItemDetailsStackCardItemOpenaiAssistantFragment
  | StackCardItemDetailsStackCardItemOpenaiChatCompletionFragment
  | StackCardItemDetailsStackCardItemOpenaiConnectFragment
  | StackCardItemDetailsStackCardItemOpenaiCreateSpeechFragment
  | StackCardItemDetailsStackCardItemOpenaiTranscribeFragment
  | StackCardItemDetailsStackCardItemRunStackFragment
  | StackCardItemDetailsStackCardItemScheduleStackFragment
  | StackCardItemDetailsStackCardItemSendContentFragment
  | StackCardItemDetailsStackCardItemSetChatPropertyFragment
  | StackCardItemDetailsStackCardItemSetMessagePropertyFragment
  | StackCardItemDetailsStackCardItemTextFragment
  | StackCardItemDetailsStackCardItemThenFragment
  | StackCardItemDetailsStackCardItemUpdateContactFragment
  | StackCardItemDetailsStackCardItemUpdateDictionaryFragment
  | StackCardItemDetailsStackCardItemVideoFragment
  | StackCardItemDetailsStackCardItemWebhookFragment
  | StackCardItemDetailsStackCardItemWhatsappFlowFragment
  | StackCardItemDetailsStackCardItemWhatsappTemplateMessageFragment
  | StackCardItemDetailsStackCardItemWriteResultFragment;

export type StackCardDetailsFragment = {
  __typename: "StackCard";
  uuid: string;
  condition: string | null;
  name: string;
  plan: Array<{
    __typename: "StackCardPlan";
    primary: Types.StackCardPlanStep | null;
    secondary: Array<Types.StackCardPlanStep | null> | null;
  }>;
  opts: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
  items: Array<
    | ({ __typename: "StackCardItemAiAddFunction" } & StackCardItemDetailsStackCardItemAiAddFunctionFragment)
    | ({ __typename: "StackCardItemAiAddMessage" } & StackCardItemDetailsStackCardItemAiAddMessageFragment)
    | ({ __typename: "StackCardItemAiAddMessages" } & StackCardItemDetailsStackCardItemAiAddMessagesFragment)
    | ({ __typename: "StackCardItemAiAssistant" } & StackCardItemDetailsStackCardItemAiAssistantFragment)
    | ({ __typename: "StackCardItemAiChatCompletion" } & StackCardItemDetailsStackCardItemAiChatCompletionFragment)
    | ({ __typename: "StackCardItemAiConnect" } & StackCardItemDetailsStackCardItemAiConnectFragment)
    | ({
        __typename: "StackCardItemAiTextClassification";
      } & StackCardItemDetailsStackCardItemAiTextClassificationFragment)
    | ({ __typename: "StackCardItemAsk" } & StackCardItemDetailsStackCardItemAskFragment)
    | ({ __typename: "StackCardItemAudio" } & StackCardItemDetailsStackCardItemAudioFragment)
    | ({ __typename: "StackCardItemButton" } & StackCardItemDetailsStackCardItemButtonFragment)
    | ({ __typename: "StackCardItemDocument" } & StackCardItemDetailsStackCardItemDocumentFragment)
    | ({ __typename: "StackCardItemDynamicButton" } & StackCardItemDetailsStackCardItemDynamicButtonFragment)
    | ({ __typename: "StackCardItemDynamicList" } & StackCardItemDetailsStackCardItemDynamicListFragment)
    | ({ __typename: "StackCardItemExpression" } & StackCardItemDetailsStackCardItemExpressionFragment)
    | ({ __typename: "StackCardItemFooter" } & StackCardItemDetailsStackCardItemFooterFragment)
    | ({ __typename: "StackCardItemHeader" } & StackCardItemDetailsStackCardItemHeaderFragment)
    | ({ __typename: "StackCardItemImage" } & StackCardItemDetailsStackCardItemImageFragment)
    | ({ __typename: "StackCardItemLelapaConnect" } & StackCardItemDetailsStackCardItemLelapaConnectFragment)
    | ({
        __typename: "StackCardItemLelapaEntityRecognition";
      } & StackCardItemDetailsStackCardItemLelapaEntityRecognitionFragment)
    | ({
        __typename: "StackCardItemLelapaIntentClassification";
      } & StackCardItemDetailsStackCardItemLelapaIntentClassificationFragment)
    | ({
        __typename: "StackCardItemLelapaSentimentAnalysis";
      } & StackCardItemDetailsStackCardItemLelapaSentimentAnalysisFragment)
    | ({ __typename: "StackCardItemLelapaTranslate" } & StackCardItemDetailsStackCardItemLelapaTranslateFragment)
    | ({ __typename: "StackCardItemList" } & StackCardItemDetailsStackCardItemListFragment)
    | ({ __typename: "StackCardItemLiteral" } & StackCardItemDetailsStackCardItemLiteralFragment)
    | ({ __typename: "StackCardItemLog" } & StackCardItemDetailsStackCardItemLogFragment)
    | ({ __typename: "StackCardItemOpenaiAddFunction" } & StackCardItemDetailsStackCardItemOpenaiAddFunctionFragment)
    | ({ __typename: "StackCardItemOpenaiAddImage" } & StackCardItemDetailsStackCardItemOpenaiAddImageFragment)
    | ({ __typename: "StackCardItemOpenaiAddMessage" } & StackCardItemDetailsStackCardItemOpenaiAddMessageFragment)
    | ({ __typename: "StackCardItemOpenaiAddMessages" } & StackCardItemDetailsStackCardItemOpenaiAddMessagesFragment)
    | ({ __typename: "StackCardItemOpenaiAssistant" } & StackCardItemDetailsStackCardItemOpenaiAssistantFragment)
    | ({
        __typename: "StackCardItemOpenaiChatCompletion";
      } & StackCardItemDetailsStackCardItemOpenaiChatCompletionFragment)
    | ({ __typename: "StackCardItemOpenaiConnect" } & StackCardItemDetailsStackCardItemOpenaiConnectFragment)
    | ({ __typename: "StackCardItemOpenaiCreateSpeech" } & StackCardItemDetailsStackCardItemOpenaiCreateSpeechFragment)
    | ({ __typename: "StackCardItemOpenaiTranscribe" } & StackCardItemDetailsStackCardItemOpenaiTranscribeFragment)
    | ({ __typename: "StackCardItemRunStack" } & StackCardItemDetailsStackCardItemRunStackFragment)
    | ({ __typename: "StackCardItemScheduleStack" } & StackCardItemDetailsStackCardItemScheduleStackFragment)
    | ({ __typename: "StackCardItemSendContent" } & StackCardItemDetailsStackCardItemSendContentFragment)
    | ({ __typename: "StackCardItemSetChatProperty" } & StackCardItemDetailsStackCardItemSetChatPropertyFragment)
    | ({ __typename: "StackCardItemSetMessageProperty" } & StackCardItemDetailsStackCardItemSetMessagePropertyFragment)
    | ({ __typename: "StackCardItemText" } & StackCardItemDetailsStackCardItemTextFragment)
    | ({ __typename: "StackCardItemThen" } & StackCardItemDetailsStackCardItemThenFragment)
    | ({ __typename: "StackCardItemUpdateContact" } & StackCardItemDetailsStackCardItemUpdateContactFragment)
    | ({ __typename: "StackCardItemUpdateDictionary" } & StackCardItemDetailsStackCardItemUpdateDictionaryFragment)
    | ({ __typename: "StackCardItemVideo" } & StackCardItemDetailsStackCardItemVideoFragment)
    | ({ __typename: "StackCardItemWebhook" } & StackCardItemDetailsStackCardItemWebhookFragment)
    | ({ __typename: "StackCardItemWhatsappFlow" } & StackCardItemDetailsStackCardItemWhatsappFlowFragment)
    | ({
        __typename: "StackCardItemWhatsappTemplateMessage";
      } & StackCardItemDetailsStackCardItemWhatsappTemplateMessageFragment)
    | ({ __typename: "StackCardItemWriteResult" } & StackCardItemDetailsStackCardItemWriteResultFragment)
  >;
};

export type TriggerDetailsFragment = {
  __typename: "Trigger";
  crontab: string | null;
  enabled: boolean;
  interval: string | null;
  profileFieldName: string | null;
  targetTime: any | null;
  triggerAt: any | null;
  type: Types.TriggerType;
  until: any | null;
  expression: ({ __typename: "Expression" } & ExpressionDetailsFragment) | null;
};

export type StackDetailsFragment = {
  __typename: "Stack";
  uuid: string | null;
  name: string | null;
  opts: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
  cards: Array<{ __typename: "StackCard" } & StackCardDetailsFragment>;
};

export type NotebookCellTableFragment = {
  __typename: "Table";
  name: string;
  headers: Array<string>;
  frontMatter: {
    __typename: "FrontMatter";
    version: string;
    columns: Array<{ __typename: "FrontMatterColumn"; key: string; type: Types.FrontMatterColumnType }>;
  };
  rows: Array<Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>>;
};

export type NotebookCellFragmentNotebookCellCode = {
  __typename: "NotebookCellCode";
  type: Types.NotebookCellType;
  code: string;
  cards: Array<{
    __typename: "StackCard";
    displayName: string | null;
    name: string;
    uuid: string;
    condition: string | null;
    items: Array<
      | ({ __typename: "StackCardItemAiAddFunction" } & StackCardItemDetailsStackCardItemAiAddFunctionFragment)
      | ({ __typename: "StackCardItemAiAddMessage" } & StackCardItemDetailsStackCardItemAiAddMessageFragment)
      | ({ __typename: "StackCardItemAiAddMessages" } & StackCardItemDetailsStackCardItemAiAddMessagesFragment)
      | ({ __typename: "StackCardItemAiAssistant" } & StackCardItemDetailsStackCardItemAiAssistantFragment)
      | ({ __typename: "StackCardItemAiChatCompletion" } & StackCardItemDetailsStackCardItemAiChatCompletionFragment)
      | ({ __typename: "StackCardItemAiConnect" } & StackCardItemDetailsStackCardItemAiConnectFragment)
      | ({
          __typename: "StackCardItemAiTextClassification";
        } & StackCardItemDetailsStackCardItemAiTextClassificationFragment)
      | ({ __typename: "StackCardItemAsk" } & StackCardItemDetailsStackCardItemAskFragment)
      | ({ __typename: "StackCardItemAudio" } & StackCardItemDetailsStackCardItemAudioFragment)
      | ({ __typename: "StackCardItemButton" } & StackCardItemDetailsStackCardItemButtonFragment)
      | ({ __typename: "StackCardItemDocument" } & StackCardItemDetailsStackCardItemDocumentFragment)
      | ({ __typename: "StackCardItemDynamicButton" } & StackCardItemDetailsStackCardItemDynamicButtonFragment)
      | ({ __typename: "StackCardItemDynamicList" } & StackCardItemDetailsStackCardItemDynamicListFragment)
      | ({ __typename: "StackCardItemExpression" } & StackCardItemDetailsStackCardItemExpressionFragment)
      | ({ __typename: "StackCardItemFooter" } & StackCardItemDetailsStackCardItemFooterFragment)
      | ({ __typename: "StackCardItemHeader" } & StackCardItemDetailsStackCardItemHeaderFragment)
      | ({ __typename: "StackCardItemImage" } & StackCardItemDetailsStackCardItemImageFragment)
      | ({ __typename: "StackCardItemLelapaConnect" } & StackCardItemDetailsStackCardItemLelapaConnectFragment)
      | ({
          __typename: "StackCardItemLelapaEntityRecognition";
        } & StackCardItemDetailsStackCardItemLelapaEntityRecognitionFragment)
      | ({
          __typename: "StackCardItemLelapaIntentClassification";
        } & StackCardItemDetailsStackCardItemLelapaIntentClassificationFragment)
      | ({
          __typename: "StackCardItemLelapaSentimentAnalysis";
        } & StackCardItemDetailsStackCardItemLelapaSentimentAnalysisFragment)
      | ({ __typename: "StackCardItemLelapaTranslate" } & StackCardItemDetailsStackCardItemLelapaTranslateFragment)
      | ({ __typename: "StackCardItemList" } & StackCardItemDetailsStackCardItemListFragment)
      | ({ __typename: "StackCardItemLiteral" } & StackCardItemDetailsStackCardItemLiteralFragment)
      | ({ __typename: "StackCardItemLog" } & StackCardItemDetailsStackCardItemLogFragment)
      | ({ __typename: "StackCardItemOpenaiAddFunction" } & StackCardItemDetailsStackCardItemOpenaiAddFunctionFragment)
      | ({ __typename: "StackCardItemOpenaiAddImage" } & StackCardItemDetailsStackCardItemOpenaiAddImageFragment)
      | ({ __typename: "StackCardItemOpenaiAddMessage" } & StackCardItemDetailsStackCardItemOpenaiAddMessageFragment)
      | ({ __typename: "StackCardItemOpenaiAddMessages" } & StackCardItemDetailsStackCardItemOpenaiAddMessagesFragment)
      | ({ __typename: "StackCardItemOpenaiAssistant" } & StackCardItemDetailsStackCardItemOpenaiAssistantFragment)
      | ({
          __typename: "StackCardItemOpenaiChatCompletion";
        } & StackCardItemDetailsStackCardItemOpenaiChatCompletionFragment)
      | ({ __typename: "StackCardItemOpenaiConnect" } & StackCardItemDetailsStackCardItemOpenaiConnectFragment)
      | ({
          __typename: "StackCardItemOpenaiCreateSpeech";
        } & StackCardItemDetailsStackCardItemOpenaiCreateSpeechFragment)
      | ({ __typename: "StackCardItemOpenaiTranscribe" } & StackCardItemDetailsStackCardItemOpenaiTranscribeFragment)
      | ({ __typename: "StackCardItemRunStack" } & StackCardItemDetailsStackCardItemRunStackFragment)
      | ({ __typename: "StackCardItemScheduleStack" } & StackCardItemDetailsStackCardItemScheduleStackFragment)
      | ({ __typename: "StackCardItemSendContent" } & StackCardItemDetailsStackCardItemSendContentFragment)
      | ({ __typename: "StackCardItemSetChatProperty" } & StackCardItemDetailsStackCardItemSetChatPropertyFragment)
      | ({
          __typename: "StackCardItemSetMessageProperty";
        } & StackCardItemDetailsStackCardItemSetMessagePropertyFragment)
      | ({ __typename: "StackCardItemText" } & StackCardItemDetailsStackCardItemTextFragment)
      | ({ __typename: "StackCardItemThen" } & StackCardItemDetailsStackCardItemThenFragment)
      | ({ __typename: "StackCardItemUpdateContact" } & StackCardItemDetailsStackCardItemUpdateContactFragment)
      | ({ __typename: "StackCardItemUpdateDictionary" } & StackCardItemDetailsStackCardItemUpdateDictionaryFragment)
      | ({ __typename: "StackCardItemVideo" } & StackCardItemDetailsStackCardItemVideoFragment)
      | ({ __typename: "StackCardItemWebhook" } & StackCardItemDetailsStackCardItemWebhookFragment)
      | ({ __typename: "StackCardItemWhatsappFlow" } & StackCardItemDetailsStackCardItemWhatsappFlowFragment)
      | ({
          __typename: "StackCardItemWhatsappTemplateMessage";
        } & StackCardItemDetailsStackCardItemWhatsappTemplateMessageFragment)
      | ({ __typename: "StackCardItemWriteResult" } & StackCardItemDetailsStackCardItemWriteResultFragment)
    >;
    opts: Array<{ __typename: "KeyValuePair"; key: string; type: Types.KeyValuePairType; value: string | null }>;
  }>;
  issues: Array<{
    __typename: "StackIssue";
    type: Types.StackIssueType;
    line: number | null;
    column: number | null;
    snippet: string | null;
    description: string;
  }>;
  triggers: Array<{ __typename: "Trigger" } & TriggerDetailsFragment>;
  section: { __typename: "NotebookCellSectionType"; name: string; x: number; y: number } | null;
};

export type NotebookCellFragmentNotebookCellDictionary = {
  __typename: "NotebookCellDictionary";
  type: Types.NotebookCellType;
  dictionary: {
    __typename: "Dictionary";
    name: string;
    frontMatter: {
      __typename: "FrontMatter";
      version: string;
      columns: Array<{ __typename: "FrontMatterColumn"; key: string; type: Types.FrontMatterColumnType }>;
    };
    items: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
  };
  section: { __typename: "NotebookCellSectionType"; name: string; x: number; y: number } | null;
};

export type NotebookCellFragmentNotebookCellMarkdown = {
  __typename: "NotebookCellMarkdown";
  type: Types.NotebookCellType;
  markdown: string;
  section: { __typename: "NotebookCellSectionType"; name: string; x: number; y: number } | null;
};

export type NotebookCellFragmentNotebookCellTable = {
  __typename: "NotebookCellTable";
  type: Types.NotebookCellType;
  table: { __typename: "Table" } & NotebookCellTableFragment;
  section: { __typename: "NotebookCellSectionType"; name: string; x: number; y: number } | null;
};

export type NotebookCellFragment =
  | NotebookCellFragmentNotebookCellCode
  | NotebookCellFragmentNotebookCellDictionary
  | NotebookCellFragmentNotebookCellMarkdown
  | NotebookCellFragmentNotebookCellTable;

export type ChoiceSummaryFragment = { __typename: "ChoiceSummary"; count: number; label: string };

export type BlockSummaryFragment = {
  __typename: "BlockSummary";
  blockName: string;
  messageType: Types.BlockSummaryMessageType;
  cardIdentity: string;
  completedTimes: number;
  language: string;
  choiceSummaries: Array<{ __typename: "ChoiceSummary" } & ChoiceSummaryFragment> | null;
};

export type StackSummaryFragment = {
  __typename: "StackSummary";
  averageInteractionSeconds: number;
  completedTimes: number;
  initiatedTimes: number;
  insertedAt: any;
};

export type StackContainerPreviewMinFragment = {
  __typename: "StackContainerPreview";
  uuid: string;
  name: string;
  isNotebookValid: boolean | null;
  enabled: boolean;
};

export type StackContainerPreviewFragment = {
  __typename: "StackContainerPreview";
  enabled: boolean;
  hasUnpublishedChanges: boolean;
  updatedAt: any;
  isCanvas: boolean;
  stackSummary: ({ __typename: "StackSummary" } & StackSummaryFragment) | null;
  triggers: Array<{ __typename: "Trigger" } & TriggerDetailsFragment>;
} & StackContainerPreviewMinFragment;

export type NotebookMinFragment = {
  __typename: "NotebookType";
  stacksValid: boolean | null;
  cells: Array<
    | ({ __typename: "NotebookCellCode" } & NotebookCellFragmentNotebookCellCode)
    | ({ __typename: "NotebookCellDictionary" } & NotebookCellFragmentNotebookCellDictionary)
    | ({ __typename: "NotebookCellMarkdown" } & NotebookCellFragmentNotebookCellMarkdown)
    | ({ __typename: "NotebookCellTable" } & NotebookCellFragmentNotebookCellTable)
  >;
};

export type NotebookDetailsFragment = {
  __typename: "NotebookType";
  doc: string | null;
  stacks: Array<{ __typename: "Stack" } & StackDetailsFragment>;
} & NotebookMinFragment;

export type SampleNotebookDetailsFragment = {
  __typename: "SampleNotebook";
  name: string | null;
  notebook: ({ __typename: "NotebookType" } & NotebookMinFragment) | null;
};

export type StackContainerDetailsFragment = {
  __typename: "StackContainer";
  uuid: string;
  enabled: boolean;
  name: string;
  hasUnpublishedChanges: boolean;
  productionNotebookRevisionUuid: string | null;
  isNotebookValid: boolean | null;
  isCanvas: boolean;
  insertedAt: any;
  updatedAt: any;
  readOnly: boolean;
  notebook: { __typename: "NotebookType" } & NotebookDetailsFragment;
  triggers: Array<{ __typename: "Trigger" } & TriggerDetailsFragment>;
  notebookRevisions: {
    __typename: "NotebookRevisionConnection";
    revisions: Array<{
      __typename: "NotebookRevisionPreview";
      accountUuid: string | null;
      insertedAt: any;
      uuid: string;
      versionName: string | null;
    } | null>;
  };
};

export type TriggerMetaDetailsFragment = {
  __typename: "NotebookCellCode";
  meta: { __typename: "NotebookCellMeta"; approximateAudienceSize: number } | null;
  issues: Array<{
    __typename: "StackIssue";
    type: Types.StackIssueType;
    line: number | null;
    column: number | null;
    snippet: string | null;
    description: string;
  }>;
};

export type SimulatorOutputMessageTypeContentDetailsFragment = {
  __typename: "SimulatorOutputMessageTypeContent";
  contentType: Types.SimulatorOutputContentType;
  mimeType: string;
  eventValue: string | null;
  rawValue: string | null;
  value: string | null;
};

export type SimulatorOutputStepDetailsFragment = {
  __typename: "SimulatorOutputStep";
  messageType: Types.MessageTypeType;
  messageTypeContent: Array<
    { __typename: "SimulatorOutputMessageTypeContent" } & SimulatorOutputMessageTypeContentDetailsFragment
  >;
};

export type SimulatorOutputStateFragment = {
  __typename: "SimulatorOutputState";
  kind: Types.OutputStateKind | null;
  content: Array<{ __typename: "SimulatorOutputStep" } & SimulatorOutputStepDetailsFragment>;
};

export type SimulatorFragment = {
  __typename: "Simulator";
  uuid: string;
  lastUserInput: string | null;
  stacks: Array<{ __typename: "Stack"; uuid: string | null; name: string | null }>;
  currentPosition: {
    __typename: "SimulatorPosition";
    card: {
      __typename: "StackCard";
      uuid: string;
      name: string;
      displayName: string | null;
      condition: string | null;
      meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
    };
    cardItem:
      | {
          __typename: "StackCardItemAiAddFunction";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemAiAddMessage";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemAiAddMessages";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemAiAssistant";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemAiChatCompletion";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemAiConnect";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemAiTextClassification";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemAsk";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemAudio";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemButton";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemDocument";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemDynamicButton";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemDynamicList";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemExpression";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemFooter";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemHeader";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemImage";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemLelapaConnect";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemLelapaEntityRecognition";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemLelapaIntentClassification";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemLelapaSentimentAnalysis";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemLelapaTranslate";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemList";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemLiteral";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemLog";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiAddFunction";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiAddImage";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiAddMessage";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiAddMessages";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiAssistant";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiChatCompletion";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiConnect";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiCreateSpeech";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemOpenaiTranscribe";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemRunStack";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemScheduleStack";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemSendContent";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemSetChatProperty";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemSetMessageProperty";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemText";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemThen";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemUpdateContact";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemUpdateDictionary";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemVideo";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemWebhook";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemWhatsappFlow";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemWhatsappTemplateMessage";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        }
      | {
          __typename: "StackCardItemWriteResult";
          type: Types.StackCardPlanStep;
          meta: Array<{ __typename: "KeyValuePair"; key: string; value: string | null; type: Types.KeyValuePairType }>;
        };
  } | null;
  context: {
    __typename: "SimulatorContext";
    language: string;
    mode: Types.SimulatorModeType;
    log: Array<string>;
    vars: any;
    waitingForUserInput: boolean;
    finished: boolean;
  } | null;
  history: Array<{
    __typename: "SimulatorInputOutputs";
    input: string | null;
    outputs: Array<{ __typename: "SimulatorOutputState" } & SimulatorOutputStateFragment>;
  } | null> | null;
};

export const ChoiceSummaryFragmentDoc = gql`
  fragment ChoiceSummaryFragment on ChoiceSummary {
    count
    label
  }
`;
export const BlockSummaryFragmentDoc = gql`
  fragment BlockSummaryFragment on BlockSummary {
    blockName
    messageType
    cardIdentity
    choiceSummaries {
      ...ChoiceSummaryFragment
    }
    completedTimes
    language
  }
  ${ChoiceSummaryFragmentDoc}
`;
export const StackContainerPreviewMinFragmentDoc = gql`
  fragment StackContainerPreviewMin on StackContainerPreview {
    uuid
    name
    isNotebookValid
    enabled
  }
`;
export const StackSummaryFragmentDoc = gql`
  fragment StackSummaryFragment on StackSummary {
    averageInteractionSeconds
    completedTimes
    initiatedTimes
    insertedAt
  }
`;
export const ExpressionConnectorDetailsFragmentDoc = gql`
  fragment ExpressionConnectorDetails on ExpressionConnector {
    connector
  }
`;
export const ExpressionArgAtomDetailsFragmentDoc = gql`
  fragment ExpressionArgAtomDetails on ExpressionArgAtom {
    type
    atom
  }
`;
export const ExpressionArgAttributeDetailsFragmentDoc = gql`
  fragment ExpressionArgAttributeDetails on ExpressionArgAttribute {
    type
    attribute
  }
`;
export const ExpressionArgStringLiteralDetailsFragmentDoc = gql`
  fragment ExpressionArgStringLiteralDetails on ExpressionArgStringLiteral {
    stringLiteral
    type
  }
`;
export const ExpressionArgBooleanLiteralDetailsFragmentDoc = gql`
  fragment ExpressionArgBooleanLiteralDetails on ExpressionArgBooleanLiteral {
    booleanLiteral
    type
  }
`;
export const ExpressionArgIntegerLiteralDetailsFragmentDoc = gql`
  fragment ExpressionArgIntegerLiteralDetails on ExpressionArgIntegerLiteral {
    integerLiteral
    type
  }
`;
export const ExpressionArgListOfStringsLiteralDetailsFragmentDoc = gql`
  fragment ExpressionArgListOfStringsLiteralDetails on ExpressionArgListOfStringsLiteral {
    listOfStrings
    type
  }
`;
export const ExpressionFunctionDetailsFragmentDoc = gql`
  fragment ExpressionFunctionDetails on ExpressionFunction {
    hasNegation
    type
    args {
      ...ExpressionArgAtomDetails
      ...ExpressionArgAttributeDetails
      ...ExpressionArgStringLiteralDetails
      ...ExpressionArgBooleanLiteralDetails
      ...ExpressionArgIntegerLiteralDetails
      ...ExpressionArgListOfStringsLiteralDetails
    }
  }
  ${ExpressionArgAtomDetailsFragmentDoc}
  ${ExpressionArgAttributeDetailsFragmentDoc}
  ${ExpressionArgStringLiteralDetailsFragmentDoc}
  ${ExpressionArgBooleanLiteralDetailsFragmentDoc}
  ${ExpressionArgIntegerLiteralDetailsFragmentDoc}
  ${ExpressionArgListOfStringsLiteralDetailsFragmentDoc}
`;
export const ExpressionDetailsFragmentDoc = gql`
  fragment ExpressionDetails on Expression {
    items {
      ...ExpressionConnectorDetails
      ...ExpressionFunctionDetails
    }
    raw
    type
  }
  ${ExpressionConnectorDetailsFragmentDoc}
  ${ExpressionFunctionDetailsFragmentDoc}
`;
export const TriggerDetailsFragmentDoc = gql`
  fragment TriggerDetails on Trigger {
    crontab
    enabled
    expression {
      ...ExpressionDetails
    }
    interval
    profileFieldName
    targetTime
    triggerAt
    type
    until
  }
  ${ExpressionDetailsFragmentDoc}
`;
export const StackContainerPreviewFragmentDoc = gql`
  fragment StackContainerPreview on StackContainerPreview {
    ...StackContainerPreviewMin
    enabled
    hasUnpublishedChanges
    updatedAt
    stackSummary {
      ...StackSummaryFragment
    }
    isCanvas
    triggers {
      ...TriggerDetails
    }
  }
  ${StackContainerPreviewMinFragmentDoc}
  ${StackSummaryFragmentDoc}
  ${TriggerDetailsFragmentDoc}
`;
export const StackCardItemDetailsFragmentDoc = gql`
  fragment StackCardItemDetails on StackCardItem {
    variableName
    type
    meta {
      key
      value
      type
    }
    ... on StackCardItemThen {
      then: payload {
        card
        expression {
          ...ExpressionDetails
        }
      }
    }
    ... on StackCardItemText {
      text: payload
    }
    ... on StackCardItemHeader {
      headerText: payload
    }
    ... on StackCardItemFooter {
      footerText: payload
    }
    ... on StackCardItemButton {
      references: payload {
        card
        label
      }
      items {
        type
        ... on StackCardItemText {
          text: payload
        }
        ... on StackCardItemImage {
          url: payload
        }
        ... on StackCardItemVideo {
          url: payload
        }
        ... on StackCardItemAudio {
          url: payload
        }
        ... on StackCardItemDocument {
          url: payload
          filename
        }
        ... on StackCardItemHeader {
          headerText: payload
        }
        ... on StackCardItemFooter {
          footerText: payload
        }
      }
    }
    ... on StackCardItemList {
      listReferences: payload {
        callToAction
        listButtons {
          card
          label
        }
      }
      listItems: items {
        type
        variableName
        ... on StackCardItemText {
          text: payload
        }
        ... on StackCardItemThen {
          then: payload {
            card
            expression {
              ...ExpressionDetails
            }
          }
        }
        ... on StackCardItemImage {
          url: payload
        }
        ... on StackCardItemHeader {
          headerText: payload
        }
        ... on StackCardItemFooter {
          footerText: payload
        }
      }
    }
    ... on StackCardItemAsk {
      prompt: payload
    }
    ... on StackCardItemExpression {
      expression: payload
    }
    ... on StackCardItemImage {
      url: payload
    }
    ... on StackCardItemVideo {
      url: payload
    }
    ... on StackCardItemAudio {
      url: payload
    }
    ... on StackCardItemDocument {
      url: payload
      filename
    }
    ... on StackCardItemUpdateContact {
      contactFields: payload {
        key
        value
        type
      }
    }
    ... on StackCardItemWriteResult {
      resultFields: payload {
        key
        value
        type
      }
    }
    ... on StackCardItemWebhook {
      webhook: payload {
        body
        method
        query
        url
        variableName
        expression
        headers {
          key
          value
          type
        }
        mode
        timeout
      }
    }
    ... on StackCardItemRunStack {
      runStack: payload {
        flowId
      }
    }
    ... on StackCardItemSendContent {
      sendContent: payload {
        contentUuid
        waitForInput
      }
    }
    ... on StackCardItemSetChatProperty {
      setChatProperty: payload {
        assignedTo
      }
    }
    ... on StackCardItemSetMessageProperty {
      setMessageProperty: payload {
        label
      }
    }
    ... on StackCardItemWhatsappTemplateMessage {
      whatsappTemplateMessage: payload {
        bodyParams
        headerParams
        urlParams
        language
        name
      }
      mediaItems {
        type
        ... on StackCardItemDocument {
          url: payload
        }
        ... on StackCardItemImage {
          url: payload
        }
        ... on StackCardItemAudio {
          url: payload
        }
        ... on StackCardItemVideo {
          url: payload
        }
      }
      buttons {
        card
        label
      }
    }
    ... on StackCardItemExpression {
      prompt: payload
    }
    ... on StackCardItemOpenaiConnect {
      config: payload {
        token
        model
      }
    }
    ... on StackCardItemOpenaiChatCompletion {
      config: payload {
        connection
      }
    }
    ... on StackCardItemOpenaiAddMessage {
      config: payload {
        connection
        role
        message
      }
    }
    ... on StackCardItemOpenaiAddMessages {
      config: payload {
        connection
        role
        messages
      }
    }
    ... on StackCardItemOpenaiAddFunction {
      config: payload {
        connection
        description
        name
        parameters
      }
    }
    ... on StackCardItemOpenaiCreateSpeech {
      config: payload {
        connection
        model
        text
        voice
      }
    }
    ... on StackCardItemOpenaiTranscribe {
      config: payload {
        connection
        language
        prompt
        url
      }
    }
    ... on StackCardItemOpenaiAddImage {
      config: payload {
        connection
        maxTokens
        message
        role
        url
      }
    }
    ... on StackCardItemAiConnect {
      config: payload {
        vendor
        model
        token
      }
    }
    ... on StackCardItemAiAssistant {
      config: payload {
        uuid
      }
    }
    ... on StackCardItemAiAddMessage {
      config: payload {
        connection
        role
        message
      }
    }
    ... on StackCardItemAiAddMessages {
      config: payload {
        connection
        role
        messages
      }
    }
    ... on StackCardItemAiAddFunction {
      config: payload {
        connection
        name
        description
        parameters
      }
    }
    ... on StackCardItemAiChatCompletion {
      config: payload {
        connection
      }
    }
    ... on StackCardItemAiTextClassification {
      config: payload {
        connection
        userInput
        systemPrompt
        options
      }
    }
  }
  ${ExpressionDetailsFragmentDoc}
`;
export const NotebookCellTableFragmentDoc = gql`
  fragment NotebookCellTable on Table {
    name
    frontMatter {
      version
      columns {
        key
        type
      }
    }
    headers
    rows {
      key
      value
      type
    }
  }
`;
export const NotebookCellFragmentDoc = gql`
  fragment NotebookCellFragment on NotebookCell {
    type
    section {
      name
      x
      y
    }
    ... on NotebookCellCode {
      code: value
      cards {
        displayName
        name
        uuid
        condition
        items {
          ...StackCardItemDetails
        }
        opts {
          key
          type
          value
        }
      }
      issues {
        type
        line
        column
        snippet
        description
      }
      triggers {
        ...TriggerDetails
      }
    }
    ... on NotebookCellDictionary {
      dictionary: value {
        name
        frontMatter {
          version
          columns {
            key
            type
          }
        }
        items {
          key
          value
          type
        }
      }
    }
    ... on NotebookCellMarkdown {
      markdown: value
    }
    ... on NotebookCellTable {
      table: value {
        ...NotebookCellTable
      }
    }
  }
  ${StackCardItemDetailsFragmentDoc}
  ${TriggerDetailsFragmentDoc}
  ${NotebookCellTableFragmentDoc}
`;
export const NotebookMinFragmentDoc = gql`
  fragment NotebookMin on NotebookType {
    cells {
      ...NotebookCellFragment
    }
    stacksValid
  }
  ${NotebookCellFragmentDoc}
`;
export const SampleNotebookDetailsFragmentDoc = gql`
  fragment SampleNotebookDetails on SampleNotebook {
    name
    notebook {
      ...NotebookMin
    }
  }
  ${NotebookMinFragmentDoc}
`;
export const StackCardDetailsFragmentDoc = gql`
  fragment StackCardDetails on StackCard {
    uuid
    condition
    name
    plan {
      primary
      secondary
    }
    opts {
      key
      value
      type
    }
    items {
      ...StackCardItemDetails
    }
  }
  ${StackCardItemDetailsFragmentDoc}
`;
export const StackDetailsFragmentDoc = gql`
  fragment StackDetails on Stack {
    uuid
    name
    opts {
      key
      value
      type
    }
    cards {
      ...StackCardDetails
    }
  }
  ${StackCardDetailsFragmentDoc}
`;
export const NotebookDetailsFragmentDoc = gql`
  fragment NotebookDetails on NotebookType {
    ...NotebookMin
    doc
    stacks {
      ...StackDetails
    }
  }
  ${NotebookMinFragmentDoc}
  ${StackDetailsFragmentDoc}
`;
export const StackContainerDetailsFragmentDoc = gql`
  fragment StackContainerDetails on StackContainer {
    uuid
    enabled
    name
    hasUnpublishedChanges
    productionNotebookRevisionUuid
    isNotebookValid
    isCanvas
    insertedAt
    updatedAt
    readOnly
    notebook {
      ...NotebookDetails
    }
    triggers {
      ...TriggerDetails
    }
    notebookRevisions(limit: 1, offset: 0) {
      revisions {
        accountUuid
        insertedAt
        uuid
        versionName
      }
    }
  }
  ${NotebookDetailsFragmentDoc}
  ${TriggerDetailsFragmentDoc}
`;
export const TriggerMetaDetailsFragmentDoc = gql`
  fragment TriggerMetaDetails on NotebookCellCode {
    meta {
      approximateAudienceSize
    }
    issues {
      type
      line
      column
      snippet
      description
    }
  }
`;
export const SimulatorOutputMessageTypeContentDetailsFragmentDoc = gql`
  fragment SimulatorOutputMessageTypeContentDetails on SimulatorOutputMessageTypeContent {
    contentType
    mimeType
    eventValue
    rawValue
    value
  }
`;
export const SimulatorOutputStepDetailsFragmentDoc = gql`
  fragment SimulatorOutputStepDetails on SimulatorOutputStep {
    messageType
    messageTypeContent {
      ...SimulatorOutputMessageTypeContentDetails
    }
  }
  ${SimulatorOutputMessageTypeContentDetailsFragmentDoc}
`;
export const SimulatorOutputStateFragmentDoc = gql`
  fragment SimulatorOutputStateFragment on SimulatorOutputState {
    kind
    content {
      ...SimulatorOutputStepDetails
    }
  }
  ${SimulatorOutputStepDetailsFragmentDoc}
`;
export const SimulatorFragmentDoc = gql`
  fragment SimulatorFragment on Simulator {
    uuid
    stacks {
      uuid
      name
    }
    currentPosition {
      card {
        uuid
        name
        displayName
        condition
        meta {
          key
          value
          type
        }
      }
      cardItem {
        type
        meta {
          key
          value
          type
        }
      }
    }
    context {
      language
      mode
      log
      vars
      waitingForUserInput
      finished
    }
    history {
      input
      outputs {
        ...SimulatorOutputStateFragment
      }
    }
    lastUserInput
  }
  ${SimulatorOutputStateFragmentDoc}
`;
