/* eslint-disable @typescript-eslint/no-explicit-any */
import { DEFAULT_APP_CONFIG } from "_constants";

const { turn_host } = window.APP_CONFIG || DEFAULT_APP_CONFIG;

export const CSRFUrl = `${turn_host}/csrf`;

export const getUploadUrl = (numberUuid) => `${turn_host}/uploads/${numberUuid}`;

export const accountUrl = `${turn_host}/api/account`;

let csrf_token: string | undefined;

/**
 * context(Pringels, 2024-07-23):
 * We keep seeing "invalid CSRF token" errors thrown on the back-end.
 * The cause of these errors is not yet known and very difficult to diagnose.
 * This is an attempt to debug the error by periodically refreshing
 * the token on the front-end.
 *
 * See: https://app.shortcut.com/turn-io/story/34768/plug-csrfprotection-invalidcsrftokenerror
 */
setInterval(() => {
  csrf_token = undefined;
}, 30000);

export const fetchToken = async () => {
  const response = await fetch(CSRFUrl, {
    method: "GET",
    credentials: "include",
  });

  return (await response.json())?.csrf_token as string;
};

export const getToken = async () => {
  if (!csrf_token) {
    csrf_token = await fetchToken();

    if (csrf_token) {
      return csrf_token;
    } else {
      console.log("UNAUTHORISED");
    }
  }

  return csrf_token;
};

type TParams = {
  mfa: string;
};

export const getAccount = async (params: TParams) => {
  let method: string;
  let headers: HeadersInit;
  let body: string | null;

  if (params.mfa && params.mfa.length === 6) {
    method = "POST";
    headers = {
      "Content-Type": "application/json",
      "x-csrf-token": await getToken(),
    };
    body = JSON.stringify(params);
  } else {
    method = "GET";
    headers = {};
    body = null;
  }

  const response = await fetch(accountUrl, {
    method: method,
    headers: headers,
    body: body,
    credentials: "include",
    redirect: "manual",
  });

  if (response.status === 200) {
    const { data } = await response.json();
    return data;
  } else {
    throw new Error("Not logged in");
  }
};

export const uploadProfileRequest = (uuid, file) => {
  const formData = new FormData();
  formData.append("name", file.name);
  formData.append("type", file.type);
  formData.append("file", file);

  return getToken().then((csrf_token) =>
    fetch(`${turn_host}/uploads/${uuid}/profile/photo`, {
      method: "POST",
      body: formData,
      credentials: "include",
      headers: {
        "x-csrf-token": csrf_token,
      },
    }).then((response) => response.json())
  );
};

export const uploadMediaRequest = (
  numberUuid: string,
  file: File
): Promise<{ attachment_id: string; external_id: string }> => {
  const formData = new FormData();
  formData.append("name", file.name);
  formData.append("type", file.type);
  formData.append("file", file);

  return getToken().then((csrf_token) =>
    fetch(`${turn_host}/uploads/${numberUuid}`, {
      method: "POST",
      body: formData,
      credentials: "include",
      headers: {
        "x-csrf-token": csrf_token,
      },
    }).then((response) => response.json())
  );
};

export const getAttachmentByExternalId = (numberUuid, externalId) => `${turn_host}/media/${numberUuid}/${externalId}`;
