import { Box, Button, CheckBox, Text } from "grommet";
import React from "react";
import styled from "styled-components";

import { spacingPx } from "lib/tokens";
import { turnTheme } from "theme";
import { AUTOMATED_MESSAGES } from "utils/constants";

import { Avatar } from "../avatar";
import { EditIconSmall } from "../icons/EditIcon";
import { Remove } from "../icons/Icons";

const StyledActionButon = styled(Button)`
  padding: 6px;
  svg {
    path {
      fill: ${turnTheme.global.colors["dark-3"]};
    }
  }
  &:hover {
    background-color: transparent;
    svg {
      path {
        fill: ${turnTheme.global.colors["dark-1"]};
      }
    }
  }
`;

/* STYLES
------------------------------------------ */
const StyledIcon = styled(Box)`
  > svg {
    fill: ${({ type }) =>
      (type === "tag1" && `${turnTheme.global.colors["label-1"]}`) ||
      (type === "tag2" && `${turnTheme.global.colors["label-2"]}`) ||
      (type === "tag3" && `${turnTheme.global.colors["label-3"]}`) ||
      (type === "tag4" && `${turnTheme.global.colors["label-4"]}`) ||
      (type === "tag5" && `${turnTheme.global.colors["label-5"]}`) ||
      (type === "tag6" && `${turnTheme.global.colors["label-6"]}`) ||
      (type === "tag7" && `${turnTheme.global.colors["label-7"]}`) ||
      (type === "tag8" && `${turnTheme.global.colors["label-8"]}`) ||
      (type === "tag9" && `${turnTheme.global.colors["label-9"]}`) ||
      `${turnTheme.global.colors["dark-3"]}`};
  }
`;

export const Tab1 = styled(Box)`
  background: ${({ type }) =>
    (type === "tag1" && `${turnTheme.global.colors["label-1-tint"]}`) ||
    (type === "tag2" && `${turnTheme.global.colors["label-2-tint"]}`) ||
    (type === "tag3" && `${turnTheme.global.colors["label-3-tint"]}`) ||
    (type === "tag4" && `${turnTheme.global.colors["label-4-tint"]}`) ||
    (type === "tag5" && `${turnTheme.global.colors["label-5-tint"]}`) ||
    (type === "tag6" && `${turnTheme.global.colors["label-6-tint"]}`) ||
    (type === "tag7" && `${turnTheme.global.colors["label-7-tint"]}`) ||
    (type === "tag8" && `${turnTheme.global.colors["label-8-tint"]}`) ||
    (type === "tag9" && `${turnTheme.global.colors["label-9-tint"]}`)};
`;

/* COMPONENTS
------------------------------------------ */

export const Tags = ({
  color,
  background,
  label,
  figure,
  type,
  avatar,
  edit,
  remove,
  select,
  fontSize,
  weight,
  checked,
  onChange,
  onEdit,
  onRemove,
  textProps,
  removeProps,
  removeIconProps,
  figureProps,
  containerProps,
  tagProps,
  ...props
}) => {
  const tag = (
    <Box
      alignItems="start"
      align="center"
      flex={false}
      margin={{ bottom: "8px" }}
      direction="row"
      gap="small"
      justify="between"
      data-test="label-tag"
      {...props}
      {...containerProps}
    >
      <Tab1
        direction="row"
        round="medium"
        background={{ color: `${background}` }}
        type={type}
        align="center"
        className="ignore-onclickoutside"
        gap="4px"
        {...props}
        {...tagProps}
      >
        {figure && (
          <StyledIcon type={type} align="center" justify="center" width="16px" height="16px">
            {figure}
          </StyledIcon>
        )}
        {avatar && <Avatar size="avatarxxSmall" name={label} background={avatar} />}
        <Text
          wordBreak="break-word"
          size={fontSize}
          color={color}
          weight={weight}
          {...textProps}
          className="ph-no-capture"
        >
          {label}
        </Text>
        {remove && (
          <StyledActionButon
            onClick={onRemove}
            icon={<Remove width={12} height={12} fill={turnTheme.global.colors["dark-4"]} {...removeIconProps} />}
            {...removeProps}
          />
        )}
        {edit && label !== AUTOMATED_MESSAGES && (
          <StyledActionButon
            onClick={onEdit}
            className="ignore-onclickoutside"
            plain
            icon={<EditIconSmall className="ignore-onclickoutside" />}
            {...figureProps}
          />
        )}
      </Tab1>
      {select && (
        <CheckBox
          className="ignore-onclickoutside"
          checked={checked}
          onChange={onChange}
          data-test={`checkbox-tag-${label?.toLowerCase()}`}
        />
      )}
    </Box>
  );

  return tag;
};
Tags.defaultProps = {
  label: "Tag",
  color: "white",
  fontSize: "medium",
  background: "dark-4",
};

export const TagLabel = (props) => {
  return (
    <Tags
      icon
      round="xlarge"
      color={props.color || "dark-1"}
      background={props.background || "light-2"}
      tagProps={{ pad: props.remove ? "4px 8px" : "4px 12px 4px 8px" }}
      {...props}
    />
  );
};
export const TagStatus = (props) => {
  return <Tags pad={{ vertical: "4px" }} width="120px" round="xsmall" weight={600} {...props} />;
};
export const TagFilter = (props) => {
  return (
    <Tags
      pad={{ vertical: spacingPx.xs, hrizontal: spacingPx.s }}
      round="xlarge"
      color={props.color || "dark-1"}
      background={props.background || "light-2"}
      weight={400}
      {...props}
    />
  );
};
export const TagMini = (props) => {
  return <Tags mini pad="0px 3px 1px" fontSize="11px" round="xxsmall" background="accent-2" {...props} />;
};
