import React from "react";

import { TSizes, TSpacing, field } from "../../styles";
import { Icon, TIcon } from "./Icon";
import { StyledFieldWrapperContainer, StyledInput, StyledLabel, StyledPrompt } from "./styles";
import { Prompts, TFieldWrapperTheme, TPromptTypes } from "./types";

interface IFieldWrapperProps {
  children: React.ReactNode;
  height?: {
    min: string;
  };
  icon?: TIcon;
  label?: React.ReactNode;
  labelProps?: Omit<JSX.IntrinsicElements["label"], "ref">;
  margin?: TSpacing;
  prompt: {
    type: TPromptTypes;
    text?: string;
  };
  size?: TSizes;
}

export const FieldWrapper = ({
  children,
  height,
  icon,
  label,
  labelProps = {},
  margin,
  prompt,
  size = "medium",
}: IFieldWrapperProps) => {
  const theme: TFieldWrapperTheme = field[size];

  return (
    <StyledFieldWrapperContainer
      data-test="form-field"
      type={prompt.type || Prompts.DEFAULT}
      margin={margin}
      {...theme}
    >
      {label && <StyledLabel {...labelProps}>{label}</StyledLabel>}
      <Icon icon={icon}>
        <StyledInput type={prompt.type || Prompts.DEFAULT} {...theme} height={height ?? theme.height}>
          {children}
        </StyledInput>
      </Icon>
      {prompt.text && (
        <StyledPrompt size="small" type={prompt.type}>
          {prompt.text}
        </StyledPrompt>
      )}
    </StyledFieldWrapperContainer>
  );
};
