import { Text } from "grommet";
import i18next from "i18next";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";
import _toPairs from "lodash/toPairs";
import React from "react";

import * as c from "_constants";
import { RoleType } from "graphql/generated/types";
import { turnTheme } from "theme";
import { WhatsAppRemark } from "utils/WhatsAppRemark";
import i18n from "utils/i18n";

export const updateChatList = (chats, updatedChat) =>
  chats.filter((chat) => updatedChat.uuid !== chat.uuid).concat([updatedChat]);

export const updateIntegrationSuggestionList = (existingIntegrationSuggestions, newIntegrationSuggestions) => [
  ...new Set([...existingIntegrationSuggestions, ...newIntegrationSuggestions]),
];

export const cleanOrderBy = (orderByValue) => (orderByValue === "Newest" ? "desc" : "asc");

export const timestampFilterToSortOrder = (orderByValue) => (orderByValue === "-timestamp" ? "desc" : "asc");

export const sortAndSliceChatList = (chats, orderByValue) =>
  _orderBy(chats, (c) => c.inboundTimestamp || c.timestamp, orderByValue).slice(0, c.CHATLIST_PAGE_SIZE);

export const sortChatList = (chats) => chats.sort((a, b) => (a.inboundTimestamp > b.inboundTimestamp ? -1 : 1));

export const sortMessageList = (messages) => messages.slice().sort((a, b) => (a.insertedAt < b.insertedAt ? -1 : 1));

export const sortIntegrationSuggestionList = (integrationSuggestions) =>
  integrationSuggestions.sort((a, b) => (a.confidence > b.confidence ? -1 : 1));

export const inspect = () => {
  return (obj) => {
    return obj;
  };
};

export const isUserAdmin = (currentOrganisation) => {
  const adminAccounts = currentOrganisation.organisationAccounts
    .filter((oa) => oa.isAdmin || oa.role === RoleType.ADMIN)
    .find((oa) => oa.account.uuid === currentOrganisation.myAccount.uuid);

  if (adminAccounts) {
    return true;
  }
  return false;
};

export const scrollIntoView = (id) => {
  const target = document.getElementById(id);
  return target === null
    ? null
    : target.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
};

export const getAccountIcon = (account) => (account.profilePicture ? `url(${account.profilePicture})` : null);

export const getChatTitle = (chat, profile) => {
  const title = chat?.title;

  if (profile && (profile.name || profile.surname)) {
    return `${profile.name} ${profile.surname}`;
  }

  return title ?? i18n.t("inbox.deleted-account");
};

export const hideWhatsAppId = (field) => {
  return {
    ...field,
    value: showConfidentialField({
      value: field.value,
      showOnStart: 4,
      showOnEnd: 4,
    }),
  };
};

export const renderMessageTemplate = (template, messageTemplates = [], fallback, options) => {
  const foundTemplate = messageTemplates.find(
    (tmpl) =>
      tmpl?.name === template.name &&
      // if there's no language set, just return true
      // also we lower case because the template.language is a GraphQL
      // enum which graphql automatically makes ALL_CAPS
      tmpl?.language.toLowerCase() === _get(template, "language.code", tmpl?.language).toLowerCase()
  );
  if (!foundTemplate) {
    return fallback;
  } else {
    // in certain, buggy, scenarios localizableParams was `null`, if that's the case
    // default to an empty list.
    const templateValues = (template.components || [])
      .filter(({ type }) => type === "BODY")
      .flatMap((component) =>
        // RapidPro can sometimes not send the parameters key
        // and if they do, sometimes it is null
        (component.parameters || []).filter(({ type }) => type === "TEXT").map(({ text }) => text)
      );

    const renderedContent = templateValues.reduce(
      (acc, current, currentIndex) => acc.replace(`{{${currentIndex + 1}}}`, current),
      foundTemplate.content
    );
    return WhatsAppRemark(renderedContent, options);
  }
};

/**
 * NOTE: the `HSM` template type is old, it's been largely replaced by the
 * `TEMPLATE` messag type which has a different structure and deals with components
 * rather than `localizableParams`.
 */
export const renderHSM = (hsm, messageTemplates = [], fallback, options) => {
  const template = messageTemplates.find(
    (template) =>
      template?.name === hsm.elementName &&
      // if there's no language set, just return true
      // also we lower case because the template.language is a GraphQL
      // enum which graphql automatically makes ALL_CAPS
      template?.language.toLowerCase() === _get(hsm, "language.code", template?.language).toLowerCase()
  );
  if (!template) {
    return fallback;
  } else {
    // in certain, buggy, scenarios localizableParams was `null`, if that's the case
    // default to an empty list.
    const templateValues = (hsm.localizableParams || []).map((param) => param.default);

    const renderedContent = templateValues.reduce(
      (acc, current, currentIndex) => acc.replace(`{{${currentIndex + 1}}}`, current),
      template.content
    );
    return WhatsAppRemark(renderedContent, options);
  }
};

export const tintForLabelColour = (color = "", fallback) => {
  if (color.includes("rgb(")) {
    return color.split(")")[0] + ",0.1)";
  }

  const label = _toPairs(turnTheme.global.colors)
    .filter(([label]) => label.includes("label-"))
    .find(([, hexValue]) => hexValue.toLowerCase() === color.toLowerCase());

  if (label) {
    const [name] = label;
    return turnTheme.global.colors[`${name}-tint`];
  }
  return fallback;
};

export const numberWaMeUrl = (number, params = {}) => {
  if (!number) return null;
  switch (number.numberType) {
    case "VIRTUAL_NUMBER":
      return numberWaMeUrl(number.numberRoute, {
        text: `join ${number.numberCode}`,
      });
    default:
      const waId = number.fromAddr.slice(1);
      const qs = Object.keys(params)
        .map((key) => key + "=" + encodeURI(params[key]))
        .join("&");
      const queryString = qs.length ? `?${qs}` : "";
      return `https://wa.me/${waId}${queryString}`;
  }
};

export const numberDisplay = (number, textProps) =>
  number.numberType === "VIRTUAL_NUMBER" ? (
    <>
      <Text size="small" weight="normal" {...textProps}>
        {`${i18next.t("settings.profile.sandbox-account")} `}
      </Text>
      <Text size="xsmall" weight="normal" {...textProps}>
        {number.numberRoute.fromAddr}
      </Text>
    </>
  ) : (
    <Text size="large" weight="normal" {...textProps}>
      {number.fromAddr}
    </Text>
  );

export const numberText = (number, showNumberOnly) =>
  number?.numberType === "VIRTUAL_NUMBER"
    ? showNumberOnly
      ? number.numberRoute.fromAddr
      : `${i18next.t("settings.profile.sandbox-account")} ${number.numberRoute.fromAddr}`
    : number?.fromAddr;

export const renameNorthAmericanCountries = (countries) => {
  const northAmerica = ["Canada", "US", "United States", "United States of America", "USA"];
  return countries.map((country) => {
    if (northAmerica.includes(country.name)) {
      return { ...country, name: "US/Canada" };
    }
    return country;
  });
};

export const templateCategoryOptions = [
  // context(Puvvl, 2023-06-01): Hide Authentication as a message template category until we can fully support it
  // { code: "AUTHENTICATION", name: "Authentication" },
  { code: "MARKETING", name: "Marketing" },
  { code: "UTILITY", name: "Utility" },
];

export const validTemplateCategories = [
  ...templateCategoryOptions,
  { code: "ACCOUNT_UPDATE", name: "Account Update" },
  { code: "PAYMENT_UPDATE", name: "Payment Update" },
  { code: "PERSONAL_FINANCE_UPDATE", name: "Personal Finance Update" },
  { code: "SHIPPING_UPDATE", name: "Shipping Update" },
  { code: "RESERVATION_UPDATE", name: "Reservation Update" },
  { code: "ISSUE_RESOLUTION", name: "Issue Resolution" },
  { code: "APPOINTMENT_UPDATE", name: "Appointment Update" },
  { code: "TRANSPORTATION_UPDATE", name: "Transportation Update" },
  { code: "TICKET_UPDATE", name: "Ticket Update" },
  { code: "ALERT_UPDATE", name: "Alert Update" },
  { code: "AUTO_REPLY", name: "Automatic reply" },
];

export const getMessageText = (message) => {
  const { content, interactive, templateButtonText } = message ?? {};

  return (
    content ||
    templateButtonText ||
    interactive?.body?.text ||
    interactive?.buttonReply?.title ||
    interactive?.listReply?.title
  );
};
