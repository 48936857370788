/* eslint-disable no-useless-concat */

/* eslint-disable no-useless-escape */
import _isString from "lodash/isString";

export const replaceAll = (targetString, str1, str2, ignore) =>
  _isString(targetString)
    ? targetString.replace(
        new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"), ignore ? "gi" : "g"),
        typeof str2 === "string" ? str2.replace(/\$/g, "$$$$") : str2
      )
    : targetString;

const unified_emoji_ranges = [
  "\ud83c[\udf00-\udfff]", // U+1F300 to U+1F3FF
  "\ud83d[\udc00-\ude4f]", // U+1F400 to U+1F64F
  "\ud83d[\ude80-\udeff]", // U+1F680 to U+1F6FF
];
const emojiRegExp = new RegExp(unified_emoji_ranges.join("|"), "g");

export const isRTL = (text) => {
  try {
    const LTRDir =
      /[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF]/gi;
    const RTLDir = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/gi;
    text = _isString(text) ? text.replace(emojiRegExp, "") : text || "";
    const LTRDirMatch = text.match(LTRDir) || [];
    const RTLDirMatch = text.match(RTLDir) || [];
    return RTLDirMatch.length > 0 && RTLDirMatch.length >= LTRDirMatch.length;
  } catch {
    console.log(`Error, ${text} is not a string`);
    return false;
  }
};

export const getTextDirection = (text) => (isRTL(text) ? "rtl" : "ltr");
