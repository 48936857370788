import _orderBy from "lodash/orderBy";
import _uniqBy from "lodash/uniqBy";

import { TChatDetails, TChatMin } from "graphql/chats";
import { TInboxGroupingSummary } from "graphql/inbox";
import { ValueOf } from "types";
import { timestampFilterToSortOrder } from "utils";
import { equalBy, insertIf } from "utils/array";
import { isDefinedAndNotNull } from "utils/checks";
import { dayjs } from "utils/date";
import i18n from "utils/i18n";

import { ChatState, OrderBy, TChatState } from "./types";

export const checkDateOn24Hours = (createdAt: string) => {
  const now = Date.now();
  const date = new Date(createdAt).getTime();
  const oneDay = 24 * 60 * 60 * 1000;

  return now - date < oneDay;
};

export const stateOptions = [
  { label: i18n.t("global.open"), value: ChatState.OPEN },
  { label: i18n.t("global.closed"), value: ChatState.CLOSED },
  { label: i18n.t("global.all"), value: ChatState.ALL },
];

export const orderByOptions = {
  chats: [
    { label: i18n.t("ordering.newest"), value: OrderBy.chats.NEWEST },
    { label: i18n.t("ordering.oldest"), value: OrderBy.chats.OLDEST },
  ],
  messages: [
    { label: i18n.t("ordering.newest"), value: OrderBy.messages.NEWEST },
    { label: i18n.t("ordering.oldest"), value: OrderBy.messages.OLDEST },
  ],
};

export const defaults = {
  orderBy: {
    chats: OrderBy.chats.NEWEST,
    messages: OrderBy.messages.NEWEST,
  },
  chatsState: ChatState.OPEN,
} as const;

export const IGNORE_CHAT_LIST_ITEM_CLICK = "ignore-chat-list-item-click";

// context(alexandrchebotar, 2023-10-05): used for polling data for Inbox sidebar and chat list
export const INBOX_POLLING_INTERVAL = 10000;

// context(2022-09-26, alexanderchebotar): ensure all chats are ordered and unique
export const ensureUniq = (chats: TChatDetails[], orderBy: string) =>
  _uniqBy(
    _orderBy(
      chats,
      ["timestamp", "updatedAt"],
      [timestampFilterToSortOrder(orderBy), timestampFilterToSortOrder(orderBy)]
    ),
    "uuid"
  );

// context(2022-09-26, alexanderchebotar): get only chats that are relevant for the current state
export const ensureByState = (chats: TChatDetails[], state: string) =>
  state === ChatState.ALL ? chats : chats.filter((chat) => chat.state === state);

export const getPersonName = (chat?: TChatMin) =>
  chat?.title?.match(/^.•*.$/) ? i18n.t("inbox.this-person") : (chat?.title ?? chat?.owner);

// context(alexandrchebotar, 2023-11-14): ensure all chats are assigned to uuid or not assigned at all (for Unassigned collection).
//    If both `uuid` and `unassigned` are not defined or false, then chats should not be filtered.
export const ensureAssignedTo = (chats: TChatDetails[], assignedToUuid?: string | null, unassigned?: boolean) =>
  chats.filter(({ assignedTo }) =>
    unassigned ? !assignedTo?.uuid : assignedToUuid ? assignedTo?.uuid === assignedToUuid : true
  );

export const CloseConversationSetting = {
  KEEP_ASSIGNMENT: "keep-assignment",
  UNASSIGN: "unassign",
} as const;
export type TCloseConversationSetting = ValueOf<typeof CloseConversationSetting>;

export const saveCloseConversationSetting = (selectedSetting: TCloseConversationSetting) =>
  window.localStorage.setItem("closeConversationSetting", selectedSetting);

export const getCloseConversationSetting = () => {
  const value = window.localStorage.getItem("closeConversationSetting");

  return Object.values(CloseConversationSetting).includes(value as TCloseConversationSetting)
    ? (value as TCloseConversationSetting)
    : CloseConversationSetting.KEEP_ASSIGNMENT;
};

export const getAssigneeName = (
  account?: {
    firstName?: string | null;
    lastName?: string | null;
  } | null,
  short?: boolean
) => {
  const { firstName, lastName } = account ?? {};
  const formattedLastName = lastName ? (short ? ` ${lastName[0]}.` : ` ${lastName}`) : "";

  return firstName ? `${firstName}${formattedLastName}` : (lastName ?? "");
};

// context(alexandrchebotar, 2023-12-12): use only the first name, and we'd only show the last name's initial if there are 2+ people with the same name (e.g.: Anna C. & Anna S.)
export const getTeammateName = (teammate?: TInboxGroupingSummary, teammates?: TInboxGroupingSummary[] | null) => {
  if (teammate && teammates) {
    const getFirstName = (name: string) => name.split(" ")[0];
    const getExtendedName = (name: string) => {
      const secondNameShort = name.split(" ")[1]?.[0];

      return `${getFirstName(name)}${secondNameShort ? ` ${secondNameShort}.` : ""}`;
    };
    const firstName = getFirstName(teammate.name);
    const restTeammates = teammates
      .filter(isDefinedAndNotNull)
      .filter(({ accountUuid }) => teammate.accountUuid !== accountUuid);

    return restTeammates.some(({ name }) => firstName === getFirstName(name))
      ? getExtendedName(teammate.name)
      : getFirstName(teammate.name);
  }

  return "";
};

export const getChatTitle = (chat?: Pick<TChatDetails, "title" | "owner">) =>
  chat
    ? (chat.title ?? (chat.owner.match(/^\+/) ? chat.owner.replace(/^\++/, "+") : i18n.t("inbox.deleted-account")))
    : "";

// context(alexandrchebotar, 2024-02-13): process current chat list and remove missing from latest query results chats that can be safety removed
export const removeMissingChats = ({
  currentChats,
  fetchedChats,
  orderBy,
}: {
  currentChats: TChatDetails[];
  fetchedChats: TChatDetails[];
  orderBy: string;
}) => {
  // context(alexandrchebotar, 2024-02-14): timestamp before/after wich we have actual information (from the latest fetched chats)
  const limitTimestamp = fetchedChats.reduce(
    (timestamp, chat) =>
      (orderBy === OrderBy.chats.OLDEST
        ? dayjs.max(dayjs(chat.timestamp), timestamp)
        : dayjs.min(dayjs(chat.timestamp), timestamp)) ?? timestamp,
    dayjs(fetchedChats[0]?.timestamp)
  );

  const isRemovedChat = (chat: TChatDetails) => {
    const isChatNotFetched = !fetchedChats.some(equalBy("uuid", chat.uuid));

    return (
      isChatNotFetched &&
      // context(alexandrchebotar, 2024-02-15): we can only handle removed chats when ordering set to NEWEST.
      //    With ordering OLDEST every chat updating moves chat to the last page even if it stays in the general query results
      orderBy === OrderBy.chats.NEWEST &&
      // context(alexandrchebotar, 2024-02-13): ensure missed chat is expected in the first page of query results.
      //    Otherwise we can't use it's missing from the fetched chats for removing it from the chat list.
      limitTimestamp.isBefore(chat.timestamp)
    );
  };

  return currentChats.filter((chat) => chat && !isRemovedChat(chat));
};

// context(alexandrchebotar, 2024-02-13): merge fetched by poll interval chats with current chat list
export const mergeChats = ({
  currentChats,
  fetchedChats,
  orderBy,
  chatsState,
  assignedToUuid,
  isUnassignedChats,
  pageSize,
  totalChats,
}: {
  currentChats: TChatDetails[];
  fetchedChats: TChatDetails[];
  orderBy: string;
  chatsState: TChatState;
  assignedToUuid?: string | null;
  isUnassignedChats?: boolean;
  pageSize: number;
  totalChats: number;
}) =>
  ensureByState(
    // context(alexandrchebotar, 2024-02-13): chats ordering is here
    ensureUniq(
      ensureAssignedTo(
        [
          // context(alexandrchebotar, 2022-06-03): `fetchedChats` must be destructed before `chats` as it contains fresh chats data
          //         and `ensureUniq` function keeps first item.  Because chat's `updatedAt` property doesn't change after marking
          //         message as handled both chat objects(at `currentChats` and `fetchedChats`) we can't sort chats properly.
          ...fetchedChats,
          ...insertIf(
            // context(alexandrchebotar, 2024-02-13): if first page (fetchedChats) contains all the chats there is no need to include any chats from current state
            pageSize < totalChats,
            ...removeMissingChats({
              currentChats,
              fetchedChats,
              orderBy,
            })
          ),
        ],
        assignedToUuid,
        isUnassignedChats
      ),
      orderBy
    ),
    chatsState
  );
