import { Box } from "ds/Box";
import { ColorBrandAccent, ColorGray100, ColorNeutralBlack, ColorNeutralWhite, ShadowHigh } from "ds/Core";
import styled from "styled-components";

import { TTooltipType, TooltipType } from "./types";

const theme = {
  [TooltipType.NEUTRAL]: {
    backgroundColor: ColorNeutralWhite,
    borderColor: ColorGray100,
    color: ColorNeutralBlack,
    marginY: "-9.5px",
  },
  [TooltipType.BOLD]: {
    backgroundColor: ColorBrandAccent,
    borderColor: ColorBrandAccent,
    color: ColorNeutralWhite,
    marginY: "-10px",
  },
  [TooltipType.DARK]: {
    backgroundColor: ColorNeutralBlack,
    borderColor: ColorNeutralBlack,
    color: ColorNeutralWhite,
    marginY: "-10px",
  },
};

export const StyledTooltip = styled(Box)<{ type?: TTooltipType; showPointer?: boolean }>`
  background-color: ${({ type }) => (type ? theme[type].backgroundColor : "")};
  border-color: ${({ type }) => (type ? theme[type].borderColor : "")};
  box-shadow: ${ShadowHigh};
  color: ${({ type }) => (type ? theme[type].color : "")};
  z-index: 9999999;

  &.tooltip-container {
    > .tooltip-arrow {
      width: 8px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after {
        content: none;
      }
      &:before {
        content: none;
      }
      > svg {
        flex-shrink: 0;
        stroke: ${({ type, showPointer }) => showPointer && type && theme[type].borderColor};
        stroke-dasharray: 31 12 8.48;
        fill: ${({ type, showPointer }) => (!showPointer ? "none" : type && theme[type].backgroundColor)};
      }
    }
  }

  &.tooltip-container {
    &[data-popper-placement*="top"] {
      .tooltip-arrow {
        margin-bottom: ${({ type }) => type && theme[type].marginY};
        > svg {
          margin-left: 2px;
          transform: rotate(180deg);
        }
      }
    }
    &[data-popper-placement*="bottom"] {
      .tooltip-arrow {
        margin-top: ${({ type }) => type && theme[type].marginY};
        > svg {
          margin-right: 2px;
        }
      }
    }
    &[data-popper-placement*="left"] {
      .tooltip-arrow {
        margin-right: -6px;
        > svg {
          margin-top: -2px;
          transform: rotate(90deg);
        }
      }
    }
    &[data-popper-placement*="right"] {
      .tooltip-arrow {
        margin-left: -6px;
        > svg {
          margin-top: 1px;
          transform: rotate(-90deg);
        }
      }
    }
    &[data-popper-placement="bottom-start"],
    &[data-popper-placement="top-start"] {
      .tooltip-arrow {
        left: -3px !important;
      }
    }
    &[data-popper-placement="bottom-end"],
    &[data-popper-placement="top-end"] {
      .tooltip-arrow {
        left: 3px !important;
      }
    }

    &[data-popper-placement="left-start"],
    &[data-popper-placement="right-start"] {
      .tooltip-arrow {
        top: 3px !important;
      }
    }
    &[data-popper-placement="left-end"],
    &[data-popper-placement="right-end"] {
      .tooltip-arrow {
        top: -3px !important;
      }
    }
  }
`;
