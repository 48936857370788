import { Country } from "countries-phone-masks";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AsYouType, CountryCode, E164Number, parsePhoneNumber } from "libphonenumber-js";

import { DropDownCarretIcon } from "../../Icons/BottomIcon";
import { Paragraph } from "../../Typography";
import { Spaces, TSizes } from "../../styles";
import { getClientLocation } from "../../utils";
import { CountryItem } from "../CountryInput/CountryItem";
import { Flag } from "../CountryInput/Flag";
import { ICountryState } from "../CountryInput/types";
import { countriesList, getCountries, getFilterCountriesFn } from "../CountryInput/utils";
import { Dropdown } from "../Dropdown";
import { FieldWrapper } from "../FieldWrapper";
import { getPrompt } from "../FieldWrapper/utils";
import { SelectDropContent } from "../SelectDropContent";
import { ITextInputProps, TextInput } from "../TextInput";
import { FAVORITE_COUNTRIES } from "../constants";
import { StyledCountryContainer, StyledDropButton, StyledInputContainer } from "./styles";
import { maskPhoneNumber, replaceCountry } from "./utils";

type TPhoneInputProps = Omit<ITextInputProps, "value" | "onChange">;

export interface IPhoneInputExtendedValue {
  number?: E164Number;
  masked?: string;
  valid?: boolean;
}
export interface IPhoneInputProps extends TPhoneInputProps {
  name: string;
  label?: string;
  error?: string;
  warning?: string;
  success?: string;
  onChange?: (value: IPhoneInputExtendedValue) => void;
  value?: string;
  skipFetchingCountry?: boolean;
  favorite?: CountryCode[];
  hideFlag?: boolean;
  size?: TSizes;
}

const defaultCountry: ICountryState | undefined = replaceCountry(countriesList.find((country) => country.iso === "US"));

export const PhoneInput = ({
  label,
  name,
  id,
  error,
  warning,
  success,
  onChange,
  value,
  skipFetchingCountry,
  favorite = FAVORITE_COUNTRIES,
  hideFlag,
  size = "large",
  ...props
}: IPhoneInputProps) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState<ICountryState | undefined>(defaultCountry);
  const [visible, setVisible] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<string>("");
  const [countryFoundByLocationApi, setCountryFoundByLocationApi] = useState<CountryCode>();

  const hideDrop = () => {
    setVisible(false);
  };
  const toggleDrop = () => {
    setVisible((visible) => !visible);
  };

  useEffect(() => {
    if (!skipFetchingCountry && !value) {
      getClientLocation()
        .then((location) => {
          const country = countriesList.find((country) => country.iso === location.countryCode);
          if (country) {
            setCountryFoundByLocationApi(country.iso);
          }
          onChooseCountry(country, true);
        })
        .catch(() => {
          onChooseCountry(defaultCountry, true);
        });
    } else if (value) {
      try {
        const parsedNumber = parsePhoneNumber(value);
        const foundCountry = countriesList.find((country) => country.iso === parsedNumber.country);
        if (foundCountry) {
          const replacedCountry = onChooseCountry(foundCountry, true);
          const inputValue = maskPhoneNumber(parsedNumber.nationalNumber as string, replacedCountry);
          setLocalValue(inputValue);
        }
      } catch (e) {
        console.log(e);
      }
    }
    // need run just in initialization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChooseCountry = (country?: Country, skipLocal?: boolean) => {
    const replacedCountry = replaceCountry(country);

    setCountry(replacedCountry);

    if (!skipLocal) {
      resetValues();
    }
    return replacedCountry;
  };

  const resetValues = () => {
    setLocalValue("");

    if (onChange) {
      onChange({
        number: "" as E164Number,
        masked: "",
        valid: false,
      });
    }

    hideDrop();
  };

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = maskPhoneNumber(event.target.value, country);

    setLocalValue(inputValue);

    if (onChange) {
      const asYouType = new AsYouType(country?.iso);
      asYouType.input(`${country?.code}${inputValue}`);
      const number = (asYouType.getNumber()?.number || "") as E164Number;
      const valid = number.length > 7 ? parsePhoneNumber(number, country?.iso).isValid() : false;

      onChange({
        valid,
        number,
        masked: `${country?.iso ?? ""} ${inputValue}`,
      });
    }
  };

  const { favorites, countries } = useMemo(
    () => getCountries(countryFoundByLocationApi ? [...favorite, countryFoundByLocationApi] : favorite),
    [countryFoundByLocationApi, favorite]
  );

  return (
    <FieldWrapper label={label} prompt={getPrompt({ error, warning, success })} labelProps={{ htmlFor: id || name }}>
      <Dropdown
        action={
          <StyledCountryContainer onClick={toggleDrop} className="ph-no-capture">
            <StyledDropButton data-test="phone-input-action">
              {!hideFlag && <Flag countryCode={country?.iso} />}
              <DropDownCarretIcon />
            </StyledDropButton>
            <Paragraph margin={{ right: Spaces.XS }} size={size}>
              {country?.code}
            </Paragraph>
          </StyledCountryContainer>
        }
        content={
          <StyledInputContainer padSize={country?.code.length} hideFlag={hideFlag} size={size}>
            <TextInput
              onChange={onChangeInput}
              data-test-gl="phone-input"
              type="tel"
              placeholder={country?.mask}
              id={id || name}
              value={localValue}
              name={name}
              size={size}
              {...props}
            />
          </StyledInputContainer>
        }
        visible={visible}
        onHide={hideDrop}
        drop={{
          width: "250px",
          minHeight: "140px",
        }}
      >
        <SelectDropContent
          items={countries}
          favorites={favorites}
          showSearch
          searchPlaceholder={t("form.placeholders.country")}
          onFilter={getFilterCountriesFn}
          onSelect={onChooseCountry}
          renderItem={(country: Country) => (
            <CountryItem key={country.iso} country={country} hideCode hideFlag={hideFlag} />
          )}
        />
      </Dropdown>
    </FieldWrapper>
  );
};
