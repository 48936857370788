/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable react-hooks/exhaustive-deps */
import { BoxProps, Button, TextInput } from "grommet";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { turnTheme } from "theme";

import { FormFieldIcon } from "../form/FormField";
import { Close } from "../icons/Close";
import { Search as SearchIcon } from "../icons/Search";

const StyledClearButton = styled(Button)`
  height: 15px;
`;

interface ISearchProps<T> extends BoxProps {
  onChange?: (text: string) => void;
  onClear?: () => void;
  placeholder?: string;
  labelKey?: string;
  options?: T[];
  onFilter?: (options?: { [k: string]: string }[] | string[]) => void;
  value: string;
  autoFocus?: boolean;
  width?: string;
  size?: string;
}

export const Search = ({
  onChange,
  onClear,
  placeholder = "Find more labels",
  labelKey,
  options = [],
  onFilter,
  value,
  autoFocus,
  size,
  ...props
}: ISearchProps<string>) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [filter, setFilter] = useState<string>("");
  const onChangeFilter = ({ target }) => {
    const { value } = target;
    setFilter(value);
    if (onChange) onChange(value);
    if (onFilter) {
      onFilter(
        options.filter((option) => {
          if (labelKey) {
            return option[labelKey].toLowerCase().includes(value.toLowerCase());
          }
          return option.toLowerCase().includes(value.toLowerCase());
        })
      );
    }
  };
  const onClearFilter = () => {
    setFilter("");
    if (onClear) {
      onClear();
    } else {
      if (onChange) {
        onChange("");
      }
      if (onFilter) {
        onFilter(options);
      }
    }
  };

  useEffect(() => {
    if (autoFocus) {
      const timerID = setTimeout(() => inputEl?.current?.focus(), 0);
      return () => {
        clearTimeout(timerID);
      };
    }
    return;
  }, [autoFocus]);

  useEffect(() => {
    if (!value && filter) {
      setFilter(value);
    }
  }, [value]);

  return (
    <FormFieldIcon
      size={size}
      direction="row"
      align="center"
      round="xsmall"
      pad={{ left: "small", right: "small" }}
      className="ignore-onclickoutside"
      {...props}
    >
      <SearchIcon fill={turnTheme.global.colors["dark-4"]} />
      <TextInput
        value={value || filter}
        onChange={onChangeFilter}
        placeholder={placeholder}
        ref={inputEl as React.RefObject<any>}
        data-test="input-search"
        size={size}
      />
      {(value || filter) && (
        <StyledClearButton onClick={onClearFilter} data-test="input-clear-search" className="ignore-onclickoutside">
          <Close width={16} height={16} fill={turnTheme.global.colors["dark-4"]} />
        </StyledClearButton>
      )}
    </FormFieldIcon>
  );
};
