import React from "react";

import { StyledIcon, StyledIconContainer } from "../TextInput/styles";

export type TIcon = { left?: React.ReactNode; right?: React.ReactNode };
interface IIconProps {
  children: JSX.Element;
  icon?: TIcon;
}

export const Icon = ({ children, icon }: IIconProps) => {
  if (!icon?.left && !icon?.right) return children;
  return (
    <StyledIconContainer left={!!icon.left} right={!!icon.right} role="img" data-test="icon">
      {icon.left ? <StyledIcon left>{icon.left}</StyledIcon> : null}
      {children}
      {icon.right ? <StyledIcon right>{icon.right}</StyledIcon> : null}
    </StyledIconContainer>
  );
};
