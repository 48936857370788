import { Space150 } from "ds/Core";
import { Box, Text, TextProps } from "grommet";
import React from "react";
import { ToastContainer, ToastContainerProps, TypeOptions } from "react-toastify";
import styled from "styled-components";

import { spacingPx } from "lib/tokens/spacing";
import { turnTheme } from "theme";

import { CloseIcon } from "../icons/CloseIcon";
import { ErrorIcon } from "../icons/ErrorIcon";
import { SuccessIcon } from "../icons/SuccessIcon";
import { WarningIcon } from "../icons/WarningIcon";

import "react-toastify/dist/ReactToastify.css";

const StyledToast = styled(Box)`
  .Toastify__toast-container {
    width: max-content;
    max-width: 715px;
    z-index: 99;
  }
  .Toastify__toast-container--bottom-right {
    bottom: 40px;
  }
  .Toastify__toast {
    background: ${turnTheme.global.colors.white};
    border: 1px solid ${turnTheme.global.colors["light-3"]};
    box-shadow: 0px 4px 10px rgba(13, 31, 38, 0.08);
    border-radius: 5px;
    border-left: 8px solid;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 18px;
    margin-bottom: 0px;
  }
  .Toastify__toast-body {
    padding-right: 16px;
  }
  .toast-title {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${turnTheme.global.colors["dark-1"]};
    margin: -3px 0 5px 0;
  }
  .toast-description {
    font-family: Inter;
    font-size: 14px;
    line-height: 22px;
    color: #7a8285;
  }
  .Toastify__toast {
    &--error {
      border-left-color: ${turnTheme.global.colors["label-1"]};
    }
    &--warning {
      border-left-color: ${turnTheme.global.colors["status-warning"]};
    }
    &--success {
      border-left-color: ${turnTheme.global.colors["status-ok"]};
    }
  }
  @media screen and (max-width: 980px) {
    .Toastify__toast-container {
      width: 90%;
    }
  }
`;

interface IToastContent {
  title?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
  titleOptions?: TextProps;
  type?: TypeOptions;
}
export const ToastContent = ({ title, description, action, titleOptions, type = "success" }: IToastContent) => (
  <Box direction="row" gap={spacingPx.m}>
    <Box flex={{ shrink: 0 }}>
      {type === "success" && <SuccessIcon />}
      {type === "error" && <ErrorIcon />}
      {type === "warning" && <WarningIcon />}
    </Box>
    <Box>
      {title && (
        <Text className="toast-title" {...titleOptions}>
          {title}
        </Text>
      )}
      {description && <Text className="toast-description">{description}</Text>}
      {action && <Box margin={{ top: Space150 }}>{action}</Box>}
    </Box>
  </Box>
);

const CloseButton = ({ closeToast }: { closeToast?: () => void }) => (
  <Box data-test="close-toast" onClick={closeToast} pad={spacingPx.xs} flex={{ shrink: 0 }} alignSelf="start">
    <CloseIcon />
  </Box>
);

export const Toast = (props: ToastContainerProps) => (
  <StyledToast>
    <ToastContainer
      icon={false}
      position="top-right"
      autoClose={30000}
      newestOnTop
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      closeButton={<CloseButton />}
      enableMultiContainer
      containerId="defaultToast"
      {...props}
    />
  </StyledToast>
);
