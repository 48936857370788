import { Avatar } from "ds/Avatar";
import { Box } from "ds/Box";
import { Dropdown } from "ds/Dropdown";
import { DropdownSize } from "ds/Dropdown/src/types";
import { Tooltip } from "ds/Tooltip";
import React, { useState } from "react";
import { down } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { StyledAvatarContainer } from "./styles";
import { MenuProps } from "./types";

export interface NavigationHeaderProps {
  collapsed?: boolean;
  numberMenu: MenuProps;
}

export const NavigationHeader = ({ numberMenu, collapsed }: NavigationHeaderProps) => {
  const isMobile = useBreakpoint(down("lg"));
  const { tooltip, ...numberMenuProps } = numberMenu;
  const [isVisibleMenu, setVisibleMenu] = useState(false);

  return (
    <Box
      padding="50"
      height="64px"
      borderBottomColor="borderColorDefault"
      borderBottomWidth="1px"
      borderBottomStyle="solid"
    >
      <Dropdown
        visible={isVisibleMenu}
        onShow={() => setVisibleMenu(true)}
        onHide={() => setVisibleMenu(false)}
        itemProps={{ size: isMobile ? DropdownSize.SMALL : DropdownSize.LARGE }}
        size={DropdownSize.LARGE}
        trigger={
          collapsed ? (
            <Tooltip
              placement="right"
              showPointer={false}
              trigger={
                <StyledAvatarContainer
                  data-test={numberMenuProps["data-test"]}
                  onClick={() => setVisibleMenu(!isVisibleMenu)}
                >
                  <Avatar
                    label={numberMenu?.avatar?.label}
                    url={numberMenu?.avatar?.url}
                    size="medium"
                    style={{ cursor: "pointer" }}
                  />
                </StyledAvatarContainer>
              }
              {...tooltip}
            />
          ) : undefined
        }
        px="50"
        {...numberMenuProps}
      />
    </Box>
  );
};
