import { SpaceNone } from "ds/Core";
import { DropdownItem } from "ds/Dropdown";
import { DropdownProps } from "ds/Dropdown/src/types";
import { MainNavigation, MainNavigationProps } from "ds/MainNavigation";
import { Icon } from "ds/icons";
import { down } from "ds/theme";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBreakpoint } from "styled-breakpoints/react-styled";

import { QRModal } from "components/shared/modal/QRModal";
import { SearchInput } from "containers/Reminders/components/ReminderContacts/components/ContactSearchMenuBar/components/Autocomplete/styles";
import { NumberType } from "graphql/generated/types";
import { TInboxGroupingSummary } from "graphql/inbox";
import { TOrganisationDetails } from "graphql/organisation";
import { TInitialNumber, TNumberMin } from "graphqlQueries/number/types";
import { useGlobalContext, useNavigation } from "hooks";
import { useLogosValidation } from "hooks/useLogosValidation";
import { SearchIcon } from "lib/icons/Search";
import { useMenu } from "utils/index";
import { FeedbackFormLink, turnLearnLink } from "utils/links";

import { StyledLabel, StyledSidebarContainer } from "./styles";

interface NavigationSidebarProps {
  organisation: TOrganisationDetails;
  organisationMenuProps: DropdownProps;
  number: TInitialNumber;
  yourInboxStats?: TInboxGroupingSummary | null;
  mainNavigationProps?: MainNavigationProps;
  collapsedSidebar?: boolean;
  offset?: `${number}px`;
}

export const NavigationSidebar = ({
  organisation,
  number,
  yourInboxStats,
  mainNavigationProps,
  organisationMenuProps,
  collapsedSidebar,
  offset = SpaceNone,
}: NavigationSidebarProps) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint(down("lg"));
  const { navigateTo } = useNavigation();

  const { menuVisible, setVisibleMenu } = useGlobalContext();

  const [showQR, setShowQR] = useState(false);
  const toggleQR = () => setShowQR((value) => !value);

  const [search, setSearch] = useState("");
  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    setSearch(search);
  };

  const getDropdownContent = (numbers: TNumberMin[]) => {
    const sortedNumbers = numbers.sort(({ vname: A }, { vname: B }) => (A < B ? -1 : 1));

    return {
      productionNumbers: sortedNumbers.filter(({ numberType }) => numberType !== NumberType.VIRTUAL_NUMBER),
      virtualNumbers: sortedNumbers.filter(({ numberType }) => numberType === NumberType.VIRTUAL_NUMBER),
    };
  };

  const onChangeNumber = (numberUuid: string) => () => {
    setVisibleMenu(false);
    navigateTo("inbox", { numberUuid })();
  };

  const { productionNumbers, virtualNumbers } = getDropdownContent(organisation.numbers);

  const filteredProductionNumbers = productionNumbers.filter(({ vname }) =>
    vname.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );
  const filteredVirtualNumbers = virtualNumbers.filter(({ vname }) =>
    vname.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  useLogosValidation({ uuid: number.uuid, profilePicture: number.profilePicture }, ...organisation.numbers);

  const menuItems = useMenu(yourInboxStats);
  const collapsed = collapsedSidebar && !isMobile;

  return (
    <>
      <StyledSidebarContainer
        width={isMobile ? "100%" : "auto"}
        height={isMobile ? "100%" : `calc(100% - ${offset})`}
        className="guide-1"
        backgroundColor="#fff"
        menuVisible={menuVisible}
        data-test="sidebar-container"
        offset={offset}
      >
        <MainNavigation
          collapsed={collapsed}
          items={menuItems}
          numberMenu={{
            "data-test": "number-dropdown-button",
            avatar: { label: number.vname, url: number.profilePicture! },
            position: "bottom-start",
            label: <StyledLabel data-test="number-title">{number.vname}</StyledLabel>,
            description: number.numberType !== NumberType.VIRTUAL_NUMBER ? number.fromAddr : t("global.virtual-number"),
            tooltip: { content: t("menu.number-menu") },
            dropProps: {
              maxHeight: isMobile ? "calc(100vh - 400px)" : "500px",
              overflow: "auto",
              className: "drop-content",
              style: {
                minHeight: "64px",
                width: isMobile ? "100%" : "initial",
              },
            },
            header:
              productionNumbers.length + virtualNumbers.length > 10 ? (
                <SearchInput
                  value={search}
                  onChange={onSearch}
                  placeholder={t("reminders.menu.search")}
                  icon={<SearchIcon />}
                />
              ) : null,
            children: filteredProductionNumbers?.map((item) => (
              <DropdownItem
                key={item.uuid}
                label={item.vname}
                description={item.fromAddr}
                avatar={{ label: item.vname, url: item.profilePicture! }}
                onClick={onChangeNumber(item.uuid)}
                selected={item.uuid === number.uuid}
                data-test="number-dropdown-item"
              />
            )),
            footer: filteredVirtualNumbers?.map((item) => (
              <DropdownItem
                key={item.uuid}
                label={item.vname}
                description={t("global.virtual-number")}
                avatar={{ label: item.vname, url: item.profilePicture! }}
                onClick={onChangeNumber(item.uuid)}
                selected={item.uuid === number.uuid}
                data-test="number-dropdown-item"
              />
            )),
          }}
          qrAction={{
            variation: "flat",
            size: "small",
            icon: <Icon name="QrCode_02Icon" />,
            onClick: toggleQR,
            className: "guide-5",
            tooltip: {
              content: t("menu.qr-code"),
              placement: "right",
              showPointer: false,
              disabled: !collapsed,
            },
          }}
          help={{
            url: turnLearnLink,
            className: "guide-4",
            tooltip: {
              content: t("menu.help"),
              placement: "right",
              showPointer: false,
              disabled: !collapsed,
            },
          }}
          feedback={{
            text: t("menu.feedback"),
            iconName: "Lightbulb_05Icon",
            anchor: {
              href: FeedbackFormLink,
              target: "_blank",
            },
          }}
          menu={organisationMenuProps}
          {...mainNavigationProps}
        />
      </StyledSidebarContainer>
      {showQR && <QRModal onClose={toggleQR} number={number} />}
    </>
  );
};
