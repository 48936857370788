import { Box, Button, DropButton, FormField, Grid, Text, TextInput } from "grommet";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useClickOutside } from "hooks/useClickOutside";
import { turnTheme } from "theme";
import { tintForLabelColour } from "utils";

import { Avatar } from "../avatar";
import { ButtonFilledBig, ButtonTextIcon, ButtonTextMute } from "../buttons";
import { FormFieldPlain } from "../form/FormField";
import { DownArrow } from "../icons/DownArrow";
import { Add, Selected } from "../icons/Icons";
import { Search as SearchIcon } from "../icons/Search";
import { Tag } from "../icons/Tag";
import { Spinner } from "../loaders";
import { ConfirmModal } from "../modal/Modal";
import { Search } from "../search";
import { TagLabel } from "../tags/Tags";
import { NavBarItem } from "./NavbarItem";

const defaultTagColour = "#1B82CC";
const colourList = [
  {
    background: "#DD5D52",
  },
  {
    background: "#E8A004",
  },
  {
    background: "#1B82CC",
  },
  {
    background: "#8155A1",
  },
  {
    background: "#E25F84",
  },
  {
    background: "#E87F47",
  },
  {
    background: "#99AD35",
  },
  {
    background: "#0D1F26",
  },
  {
    background: "#10A86E",
  },
];
const navBarItems = [
  {
    // Icon: MomConnect,
    title: "MomConnect",
    route: "",
    active: "true",
  },
  {
    // Icon: NurseConnect,
    title: "Answers",
    route: "",
  },
];

/* STYLES
------------------------------------------ */

const StyledDropIconButton = styled(DropButton)`
  opacity: 0.8;
  padding: 8px;
  background: #f6f8f8;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: all 0.15s ease-in;

  &:hover {
    opacity: 1;
    background: #ebeded;
  }
  ${(props) =>
    props.plain &&
    `background:transparent;
    &:hover{background:transparent}`}
  ${(props) => props.disabled && "opacity:0.5"}
`;

const StyledDropButtonTextIcon = styled(DropButton)`
  transition: all 0.15s ease-in;
  color: ${({ color }) => color || turnTheme.global.colors.brandTone};
  border-color: transparent;
  padding: ${({ padding }) => padding || "6px 16px"};
  border-radius: 32px;

  & svg {
    transition: all 0.15s ease-in;
    fill: ${({ color }) => color || turnTheme.global.colors.brandTone};
    width: 16px;
    height: 16px;

    & path {
      fill: ${({ color }) => color || turnTheme.global.colors.brandTone};
    }
  }
  &:hover {
    color: ${({ color }) => color || turnTheme.global.colors["dark-3"]};

    & svg {
      fill: ${({ color }) => color || turnTheme.global.colors["dark-3"]};

      & path {
        fill: ${({ color }) => color || turnTheme.global.colors["dark-3"]};
      }
    }
  }
`;

export const DropMenuBtn = styled(Button)`
  padding: 8px 24px;
  font-weight: 400;
  font-size: 14px;
  & > div {
    justify-content: space-between;
    width: 100%;
  }
`;

export const DropMenuItem = styled(Box)`
  padding: 8px 24px;
`;

/* COMPONENTS
------------------------------------------ */
export const DropMenuContainer = ({ children, ...props }) => {
  return (
    <Box flex="grow" gap="xxsmall" width="280px" pad="8px 0" {...props}>
      {children}
    </Box>
  );
};
// Dropdown Styles
export const DropButtonIcon = (props) => {
  return <StyledDropIconButton {...props} />;
};
export const DropButtonTextIcon = (props) => {
  return <StyledDropButtonTextIcon {...props} />;
};

// TODO: Consider removing this component as it currently only exists in storybook
export const NavBarDrop = () => (
  <Box elevation="medium" width="320px" round="xsmall" border overflow="hidden">
    {navBarItems.map(({ title, active }, index) => (
      <NavBarItem key={index} title={title} subtitle="+4912412121212" active={active} />
    ))}
  </Box>
);

export const SelectedTag = ({ width = 16, height = 16, fill, ...props }) => {
  return (
    <div style={{ margin: "0.6em 0 0 0.6em" }} {...props}>
      <Selected width={width} height={height} fill={fill} />
    </div>
  );
};

export const CollectionTagMenu = ({
  number,
  onAssignTag,
  onUnAssignTag,
  onCreateNumberTag,
  onDeleteNumberTag,
  onUpdateNumberTag,
  selectedTags,
}) => {
  const [loading, setLoading] = useState(false);
  const [createTagName, setCreateTagName] = useState("");
  const [createTagColour, setCreateTagColour] = useState(defaultTagColour);

  const [updateTagName, setUpdateTagName] = useState("");
  const [updateTagColour, setUpdateTagColour] = useState("");

  const [isDropMenuOpen, setDropMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("SELECT_NUMBER_TAG_MENU");
  const [tagFilter, setTagFilter] = useState("");
  const [editingNumberTag, setEditingNumberTag] = useState({});
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

  const [clickOutsideRef] = useClickOutside(() => setDropMenuOpen(false));

  // TODO: Separate all these inline components out
  const createNumberTagMenu = (
    <>
      <DropMenuItem>
        {/* Form */}
        <FormField label="Form Label" htmlFor="text-input">
          <TextInput
            id="text-input-1"
            placeholder="Start typing here..."
            onChange={({ target: { value } }) => {
              setCreateTagName(value);
            }}
            value={createTagName}
          />
        </FormField>
        {/* Label Colors */}
        <FormField label="Label Colors">
          <Grid margin={{ bottom: "8px" }} gap="small" columns={{ count: 6, size: "32px" }}>
            {colourList.map((colorItem, i) => (
              <Box
                key={i}
                round="100%"
                background={{
                  color: `${colorItem.background}`,
                }}
                width="32px"
                height="32px"
                flex={false}
                onClick={() => {
                  setCreateTagColour(colorItem.background);
                }}
              >
                {colorItem.background === createTagColour && <SelectedTag fill={turnTheme.global.colors["light-1"]} />}
              </Box>
            ))}
          </Grid>
        </FormField>
      </DropMenuItem>
      {/* Actions */}
      <Box border={{ side: "top", color: "border" }} />
      <DropMenuItem direction="row" gap="16px 16px">
        <ButtonFilledBig
          label="Add label"
          icon={loading ? <Spinner color={turnTheme.global.colors.brand} /> : null}
          disabled={createTagName === "" || createTagColour === ""}
          onClick={() => {
            setLoading(true);
            onCreateNumberTag({
              color: createTagColour,
              value: createTagName,
            }).then(() => {
              setLoading(false);
              setDropMenuOpen(false);
              setActiveMenu("SELECT_NUMBER_TAG_MENU");
              setCreateTagName("");
              setTagFilter("");
              setCreateTagColour("");
            });
          }}
        />
        <ButtonTextMute
          onClick={() => {
            setDropMenuOpen(false);
            setActiveMenu("SELECT_NUMBER_TAG_MENU");
          }}
          label="Cancel"
        />
      </DropMenuItem>
    </>
  );

  const updateNumberTagMenu = (
    <>
      <DropMenuItem>
        {/* Form */}
        <FormField label="Form Label" htmlFor="text-input">
          <TextInput
            id="text-input-1"
            placeholder="Start typing here..."
            onChange={({ target: { value } }) => {
              setUpdateTagName(value);
            }}
            value={updateTagName}
          />
        </FormField>
        {/* Label Colors */}
        <FormField label="Label Colors">
          <Grid margin={{ bottom: "8px" }} gap="small" columns={{ count: 6, size: "32px" }}>
            {colourList.map((colorItem, i) => (
              <Box
                key={i}
                round="100%"
                background={{
                  color: `${colorItem.background}`,
                }}
                width="32px"
                height="32px"
                flex={false}
                onClick={() => {
                  setUpdateTagColour(colorItem.background);
                }}
              >
                {colorItem.background === updateTagColour && <SelectedTag fill={turnTheme.global.colors["light-1"]} />}
              </Box>
            ))}
          </Grid>
        </FormField>
      </DropMenuItem>
      {/* Actions */}
      <Box border={{ side: "top", color: "border" }} />
      <DropMenuItem direction="row" gap="16px 16px">
        <ButtonFilledBig
          label={loading ? "Saving..." : "Save changes"}
          icon={loading ? <Spinner color={turnTheme.global.colors.brand} size={12} /> : null}
          disabled={updateTagName === "" || updateTagColour === ""}
          onClick={() => {
            setLoading(true);
            onUpdateNumberTag({
              numberTagUuid: editingNumberTag.uuid,
              value: updateTagName,
              color: updateTagColour,
            }).then(() => {
              setLoading(false);
              setDropMenuOpen(false);
              setActiveMenu("SELECT_NUMBER_TAG_MENU");
              setEditingNumberTag({});
              setUpdateTagName("");
              setUpdateTagColour("");
            });
          }}
        />
        <ButtonTextMute
          onClick={() => {
            setDropMenuOpen(false);
            setDeleteModalVisible(true);
          }}
          label="Archive"
        />
      </DropMenuItem>
    </>
  );

  const selectNumberTagMenu = (
    <>
      <div ref={clickOutsideRef}>
        <DropMenuItem>
          {/* Form */}
          <FormFieldPlain
            flex={false}
            overflow="auto"
            round="xsmall"
            direction="row"
            align="center"
            pad={{ left: "18px" }}
          >
            <SearchIcon fill={turnTheme.global.colors["dark-4"]} />
            <TextInput
              width="80%"
              placeholder="Start typing here..."
              onChange={({ target: { value } }) => setTagFilter(value)}
              value={tagFilter}
            />
          </FormFieldPlain>
          {/* Label Colors */}
          <Box height="200px" overflow="auto">
            <Box width="100%">
              {number.numberTags
                .filter((numberTag) =>
                  tagFilter ? numberTag.value.toLowerCase().includes(tagFilter.toLowerCase()) : true
                )
                .map((numberTag) => ({
                  numberTag: numberTag,
                  color: numberTag.color,
                  tint: tintForLabelColour(numberTag.color, turnTheme.global.colors["label-8-tint"]),
                  checked: selectedTags.map((numberTag) => numberTag.uuid).indexOf(numberTag.uuid) > -1,
                }))
                .map(({ numberTag, color, tint, checked }) => (
                  <TagLabel
                    checked={checked}
                    edit
                    onEdit={() => {
                      setEditingNumberTag(numberTag);
                      setUpdateTagName(numberTag.value);
                      setUpdateTagColour(numberTag.color);
                      setActiveMenu("UPDATE_NUMBER_TAG_MENU");
                    }}
                    onChange={() => {
                      setLoading(true);
                      return checked
                        ? onUnAssignTag(numberTag).then(() => setLoading(false))
                        : onAssignTag(numberTag).then(() => setLoading(false));
                    }}
                    select
                    key={numberTag.uuid}
                    label={numberTag.value}
                    background={tint}
                    figure={<Tag fill={color} />}
                  />
                ))}
            </Box>
          </Box>
        </DropMenuItem>
        {/* Actions */}
        <Box
          border={{
            side: "top",
            color: "border",
          }}
        />
        <DropMenuItem direction="row" gap="16px 16px">
          <ButtonTextIcon
            icon={<Add />}
            label="Add label"
            onClick={() => {
              setCreateTagName(tagFilter);
              setActiveMenu("CREATE_NUMBER_TAG_MENU");
            }}
          />
        </DropMenuItem>
      </div>
    </>
  );

  const deleteModal = (
    <ConfirmModal
      loading={loading}
      onClose={() => setDeleteModalVisible(null)}
      onCancel={() => {
        setDeleteModalVisible(false);
        setActiveMenu("SELECT_NUMBER_TAG_MENU");
      }}
      onConfirm={() => {
        setLoading(true);
        return onDeleteNumberTag(editingNumberTag.uuid).then(() => {
          setLoading(false);
          setActiveMenu("SELECT_NUMBER_TAG_MENU");
          setDeleteModalVisible(false);
        });
      }}
      question={
        <>
          Do you really want to archive the <strong>{editingNumberTag.value}</strong> label?
        </>
      }
      confirmText="Yes, archive for everyone"
    />
  );

  return (
    <>
      {isDeleteModalVisible && deleteModal}
      <DropButtonIcon
        open={isDropMenuOpen}
        onClick={() => setDropMenuOpen(!isDropMenuOpen)}
        dropAlign={{
          top: "bottom",
          left: "left",
        }}
        plain
        icon={<Tag fill={turnTheme.global.colors["dark-4"]} />}
        dropContent={
          <DropMenuContainer width="300px">
            {activeMenu === "CREATE_NUMBER_TAG_MENU" && createNumberTagMenu}
            {activeMenu === "SELECT_NUMBER_TAG_MENU" && selectNumberTagMenu}
            {activeMenu === "UPDATE_NUMBER_TAG_MENU" && updateNumberTagMenu}
          </DropMenuContainer>
        }
      />
    </>
  );
};

export const NavBarDropAccountMenu = ({ title, avatar, actions, organisations, ...props }) => {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState("");
  const [isOrganisationDropOpen, setOrganisationDropOpen] = useState(false);

  const getfilteredOrganisations = () =>
    organisations
      .filter(({ name }) => name.toLowerCase().includes(filterValue))
      .sort(({ name: A }, { name: B }) => (A < B ? -1 : 1));

  const openOrganisationsDropdown = () => {
    setOrganisationDropOpen(true);
  };
  const closeOrganisationsDropdown = () => {
    setFilterValue("");
    setOrganisationDropOpen(false);
  };

  const getRunFnAndCloseDropdownFn = (fn) => () => {
    fn();
    closeOrganisationsDropdown();
  };

  return (
    <Box direction="row" gap="xsmall" align="center" pad={{ horizontal: "24px" }} height="32px" {...props}>
      <Avatar size="avatarxSmall" background={avatar} />
      <DropButtonIcon
        data-test="account-drop-button"
        plain
        dropAlign={{
          top: "bottom",
          right: "right",
        }}
        icon={<DownArrow />}
        onOpen={closeOrganisationsDropdown}
        dropContent={
          <DropMenuContainer width="320px">
            <DropMenuItem>
              <Box gap="small" direction="row" justify="between" pad="8px 0 10px">
                <Avatar background={avatar} size="avatarSmall" flex={false} />
                <Box direction="column" flex="grow">
                  <Text size="large" weight={600} data-test="organisation-title">
                    {title}
                  </Text>
                  <DropButtonTextIcon
                    reverse
                    open={isOrganisationDropOpen}
                    onOpen={openOrganisationsDropdown}
                    onClose={closeOrganisationsDropdown}
                    dropAlign={{
                      top: "bottom",
                      right: "right",
                    }}
                    style={{
                      fontSize: "12px",
                      marginLeft: "-16px",
                      alignSelf: "start",
                    }}
                    icon={<DownArrow />}
                    label={t("btn.change-organisation")}
                    dropProps={{
                      justify: "start",
                    }}
                    dropContent={
                      <DropMenuContainer>
                        <Box key="filter" pad="12px 24px">
                          <Search
                            autoFocus
                            width="100%"
                            value={filterValue}
                            placeholder={t("form.placeholders.find-organisation")}
                            onChange={(value) => {
                              setFilterValue(value.toLowerCase());
                            }}
                          />
                        </Box>
                        {[
                          ...getfilteredOrganisations().map(({ name, onClick }, index) => (
                            <DropMenuBtn
                              plain
                              key={index}
                              label={name}
                              onClick={getRunFnAndCloseDropdownFn(onClick)}
                              data-test="organisation-dropdown-item"
                            />
                          )),
                        ]}
                      </DropMenuContainer>
                    }
                  />
                </Box>
              </Box>
            </DropMenuItem>
            <Box
              border={{
                side: "top",
                color: "border",
              }}
            />
            {actions.map(({ label, onClick, href, dataTest }, index) => (
              <DropMenuBtn
                plain
                key={index}
                label={label}
                onClick={onClick ? onClick : null}
                href={href ? href : null}
                data-test={dataTest}
              />
            ))}
          </DropMenuContainer>
        }
      />
    </Box>
  );
};
